<template>
  <render ref="content" />
</template>

<script setup lang="ts">
import { ref, useSlots } from 'vue'
import { cartChangeResource } from '@services/cart.service'
import { useShowModal } from '@composables/useModalOpener'
import { useCartStore } from '@store/cart'
import { useSimpleErrorsGetter } from '@/components/Forms/FormMixin'
import { useNotification } from '@kyvg/vue3-notification'
import useGlobal from '@composables/useGlobal'

const props = defineProps({
  itemId: {
    type: [String, Number],
  },
  type: {
    type: String,
  },
  quantity: {
    type: [String, Number],
  },
})

const slots = useSlots() as any

const { notify } = useNotification()

const { $t } = useGlobal()

const isLoad = ref(false)

const getCartData = () => {
  const cartStore = useCartStore()

  cartStore.getCartData()
}

const removeFromCart = () => {
  if (isLoad.value) return

  isLoad.value = true

  const formdata = {
    entities: [{
      element: {
        type: props.type,
        id: props.itemId.toString(),
      },
      quantity: Number(props.quantity) * -1,
    }]
  }
  cartChangeResource.execute({}, formdata).then(() => {
    notify({ title: $t('Товар удален из корзины'), type: 'success' })

    getCartData()
  }).catch(async (e: object) => {
    const parsed = await useSimpleErrorsGetter(e)

    const { entities } = parsed

    const text = $t('Обратитесь к администратору сайта для получения подробной информации')

    if (entities && entities[0]) {
      const title = entities[0].nonFieldErrors[0].message

      const message = {
        title,
        text,
      }
      useShowModal(message)
    } else {
      const message = {
        title: $t('Возникла непредвиденная ошибка'),
        text,
      }
      useShowModal(message)
    }
  }).finally(() => {
    isLoad.value = false
  })
}

const render = () => slots.default({
  removeFromCart,
})
</script>
