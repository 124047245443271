import { defineComponent as _defineComponent } from 'vue'
import { ref, watch, computed, onMounted } from 'vue'
import { debounce } from 'lodash'
import { createResource } from '@/resource/resource'

const INITIAL_LIMIT = 20


export default /*@__PURE__*/_defineComponent({
  __name: 'ControlAutocomplete',
  props: {
  modelValue: {},
  filter: Object,
  config: Object,
  resetTrigger: Boolean,
  emitFullValue: Boolean,
  inputClass: String,
  placeholder: String,
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit

const model = ref(props.modelValue)
const filterLabel = props.filter.meta.title || 'title'
const result = ref({
  items: [],
  pagination: {
    limit: INITIAL_LIMIT,
    offset: 0,
    total: 0,
  },
})
const isPagination = ref(false)
const query = ref('')

const hasNextPage = computed(() => {
  const { pagination } = result.value

  return pagination.total > pagination.limit + pagination.offset
})

const resource = computed(() => {
  const { meta } = props.filter
  const queryParams = ['limit', 'offset']

  if (meta.initialParams) {
    Object.keys(meta.initialParams).forEach(key => {
      queryParams.push(key)
    })
  }

  if (meta.query_param) {
    queryParams.push(meta.query_param)
  }

  const queryString = queryParams.join(',')

  return createResource(`${meta.url}{?${queryString}}`)
})

const update = (value: any) => {
  const valuekey = props.filter.meta.value

  if (!value) {
    emit('update:modelValue', value)

    return
  }

  if (props.filter.multiple) {
    const normalizedTo = value.map(el => el[valuekey])

    emit('update:modelValue', normalizedTo)
  } else if (props.emitFullValue) {
    emit('update:modelValue', value)
  } else {
    const normalizedTo = value[valuekey]

    emit('update:modelValue', normalizedTo)
  }
}

const normalizeFrom = (val: any): string[] => {
  if (Array.isArray(val)) {
    return val
  } else {
    return [val]
  }
}

const setValueFromUrl = async (val: any) => {
  if (!val) return

  const normalizedValue = normalizeFrom(val)
  const valuekey = props.filter.meta.value
  const checked = result.value.items.filter(el => {
    return normalizedValue.find(v => (v).toString() === (el[valuekey]).toString())
  })

  if (checked.length) {
    if (props.filter.multiple) {
      model.value = checked
    } else {
      model.value = checked[0]
    }
  } else if (hasNextPage.value) {
    await setVisibility(true)

    preselectValue()
  }
}

const receive = async () => {
  const { pagination } = result.value
  const preparedParams = {
    limit: pagination.limit,
    offset: pagination.offset,
  }

  const { meta } = props.filter

  if (meta && meta.initialParams) {
    Object.keys(meta.initialParams).forEach(key => {
      preparedParams[key] = meta.initialParams[key]
    })
  }

  if (query.value) {
    preparedParams[props.filter.meta.query_param] = query.value
  }

  const { data } = await resource.value.execute(preparedParams)

  if (isPagination.value) {
    result.value.items.push(...data.items)
    result.value.pagination = data.pagination
  } else {
    result.value = data
  }
}

const search = (val: string) => {
  isPagination.value = false

  result.value.pagination.limit = INITIAL_LIMIT
  result.value.pagination.offset = 0
  result.value.pagination.total = 0

  query.value = val

  receive()
}

const setVisibility = async (isReached: boolean) => {
  if (isReached) {
    const { limit, offset } = result.value.pagination

    result.value.pagination.offset = offset + limit

    isPagination.value = true

    await receive()
  }
}

const preselectValue = () => {
  if (props.modelValue) {
    setValueFromUrl(props.modelValue)
  }
}

watch(() => props.modelValue, () => {
  setValueFromUrl(props.modelValue)
})

watch(() => props.resetTrigger, () => model.value = null)

const debounceUpdate = debounce(update, 100)
const debounceSearch = debounce(search, 500)

onMounted(async () => {
  await receive()

  preselectValue()
})

const __returned__ = { props, emit, INITIAL_LIMIT, model, filterLabel, result, isPagination, query, hasNextPage, resource, update, normalizeFrom, setValueFromUrl, receive, search, setVisibility, preselectValue, debounceUpdate, debounceSearch, ref, watch, computed, onMounted, get debounce() { return debounce }, get createResource() { return createResource } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})