import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "quantity-field" }
const _hoisted_2 = { class: "quantity-field__element" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_minus = _resolveComponent("icon-minus")!
  const _component_control_input = _resolveComponent("control-input")!
  const _component_icon_plus = _resolveComponent("icon-plus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "quantity-field__icon quantity-field__icon--variant_minus",
      onClick: _withModifiers($setup.counterDecrease, ["prevent"])
    }, [
      _createVNode(_component_icon_minus)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_control_input, {
        modelValue: $setup.localCount,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.localCount) = $event)),
        name: "quantity",
        type: "number",
        disabled: $props.isLoad,
        autocomplete: "off",
        "set-initial-value": false,
        onBlur: $setup.blurEvent,
        onInput: $setup.debounceUpdate
      }, null, 8, ["modelValue", "disabled", "onInput"])
    ]),
    _createElementVNode("div", {
      class: "quantity-field__icon quantity-field__icon--variant_plus",
      onClick: _withModifiers($setup.counterIncrease, ["prevent"])
    }, [
      _createVNode(_component_icon_plus)
    ])
  ]))
}