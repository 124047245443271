<template>
  <render />
</template>

<script setup lang="ts">
import { useSlots } from 'vue'
import { useLikesStore } from '@store/likes'

const slots = useSlots()

const likesStore = useLikesStore()

const render = () => slots.default({
  quantity: likesStore.likesInfo.count || 0,
  isDeterminated: likesStore.likesInfo.isDeterminated,
})
</script>
