import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, useSlots } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiSidebarToggler',
  setup(__props) {

const slots = useSlots()

const isActive = ref(false)

const open = () => {
  const bodyEl = document.querySelector('body') as HTMLElement

  isActive.value = true

  bodyEl.classList.add('is-overflow')
}

const hide = () => {
  const bodyEl = document.querySelector('body') as HTMLElement

  isActive.value = false

  bodyEl.classList.remove('is-overflow')
}

const render = () => slots.default({
  isActive: isActive.value,
  open,
  hide,
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(render))
}
}

})