import { defineStore } from 'pinia'
import {
  comparisonRetrieveResource,
  comparisonCountResource,
} from '@services/comparison.service'

export const useComparisonStore = defineStore('comparison', {
  state: () => ({
    comparison: {
      items: [],
      count: 0,
      isDeterminated: false,
    },
    lastAction: {
      added: null,
      removed: null,
    },
  }),

  getters: {
    comparisonInfo: state => state.comparison,
    lastActionInfo: state => state.lastAction,
  },

  actions: {
    async getComparisonCount() {
      const { data: { item: { count } } } = await comparisonCountResource.execute()

      this.setComparisonCount(count)
    },

    async getComparisonData() {
      const { data: { items } } = await comparisonRetrieveResource.execute()

      this.setComparisonInfo(items)
    },

    setComparisonInfo(items: any) {
      this.comparison.isDeterminated = true

      this.comparison.items = items
    },

    setComparisonCount(count: number) {
      this.comparison.count = count
    },

    setRemovedId(val: number | null) {
      this.lastAction.removed = val
    },

    setAddedId(val: number | null) {
      this.lastAction.added = val
    },
  },
})
