import { defineComponent as _defineComponent } from 'vue'
import { debounce } from 'lodash'
import { onMounted, ref, reactive, computed, nextTick } from 'vue'
import { searchResource } from '@services/grouped-search.service'

const MIN_LENGTH = 2


export default /*@__PURE__*/_defineComponent({
  __name: 'UiSearch',
  setup(__props, { expose: __expose }) {
  __expose();

const formdata = reactive({
  search: '',
})
const result = ref({
  collections: { items: [] },
  products: { items: [] },
  news: { items: [] },
  projects: { items: [] },
})
const recommendedProducts = ref([])
const isLoading = ref(false)
const searchDebounceFn = ref(null)
const isFocused = ref(false)

const isSearch = computed(() => {
  return MIN_LENGTH <= formdata.search.length
})

const open = () => {
  isFocused.value = true

  if (!recommendedProducts.value.length && !isSearch.value) {
    startSearch()
  }
}

const clear = () => {
  formdata.search = ''
}

const resetResult = () => {
  result.value = {
    collections: { items: [] },
    products: { items: [] },
    news: { items: [] },
    projects: { items: [] },
  }
}

const hide = () => {
  isFocused.value = false

  recommendedProducts.value = []

  resetResult()

  clear()
}

const trimSearch = async () => {
  await nextTick()

  formdata.search = formdata.search.trimStart().replace(/\s\s+/g, ' ')
}

const startSearch = async () => {
  resetResult()

  await nextTick()

  const { search } = formdata

  if (search.length >= MIN_LENGTH || search.length < MIN_LENGTH) {
    isLoading.value = true

    searchResource.execute({ search }).then(res => {
      const { data } = res

      const keys = ['products', 'collections', 'news', 'projects']

      keys.forEach(k => {
        if (!data.items[k]) {
          data.items[k] = { items: [] }
        }
      })

      result.value = data.items

      recommendedProducts.value = data.items.recommendedProducts || []

      isLoading.value = false
    }).catch(() => {
      isLoading.value = false
    })
  }
}

onMounted(() => {
  searchDebounceFn.value = debounce(startSearch, 800)
})

const __returned__ = { MIN_LENGTH, formdata, result, recommendedProducts, isLoading, searchDebounceFn, isFocused, isSearch, open, clear, resetResult, hide, trimSearch, startSearch, get debounce() { return debounce }, onMounted, ref, reactive, computed, nextTick, get searchResource() { return searchResource } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})