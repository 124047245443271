import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutDelivery',
  props: {
  currentCountry: {
    type: String,
  },
  formdata: {
    type: Object,
  },
  errors: {
    type: Object,
  },
  choices: {
    type: Object,
  },
},
  emits: ['postalcode:changed', 'delivery:way:changed'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit

const isPickup = computed(() => {
  return props.formdata.deliveryWay?.service === 'pickup'
})
const isCourier = computed(() => {
  return props.formdata.deliveryWay?.service === 'courier'
})
const hasDescription = computed(() => {
  return Boolean(props.formdata.deliveryWay?.description)
})

const __returned__ = { props, emit, isPickup, isCourier, hasDescription, computed }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})