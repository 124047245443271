import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ds-caption ds-caption--color_relief-4 ds-caption--size_xs ds-caption--size_sm-xl" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("p", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString($props.text), 1),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _createElementVNode("a", {
      class: "ds-link ds-link--color_main ds-link--size_xs ds-link--size_sm-xl ds-link--inline",
      href: $setup.privacyPolicyUrl,
      target: "_blank"
    }, _toDisplayString(_ctx.$t("политикой конфиденциальности")), 9, _hoisted_2),
    _createTextVNode(" " + _toDisplayString(_ctx.$t("и")) + " ", 1),
    _createElementVNode("a", {
      class: "ds-link ds-link--color_main ds-link--size_xs ds-link--size_sm-xl ds-link--inline",
      href: $setup.termsOfUseUrl,
      target: "_blank"
    }, _toDisplayString(_ctx.$t("правилами сайта")), 9, _hoisted_3)
  ]))
}