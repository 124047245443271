import { defineComponent as _defineComponent } from 'vue'
import { ref, reactive, watch, onMounted, nextTick } from 'vue'
import { comparisonProductsResource } from '@services/comparison.service'
import { useComparisonStore } from '@store/comparison'
import { useWindowSize } from '@vueuse/core'


export default /*@__PURE__*/_defineComponent({
  __name: 'CompareView',
  props: {
  currentCategoryId: {
    type: Number,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const comparisonStore = useComparisonStore()

const { width: windowWidth } = useWindowSize()

const isLoading = ref(true)

const scroller = ref(null)
const scrollerContentElement = ref(null)
const scrollerScrollWidth = ref('auto')
const rowHeight = ref({})
const attributes = ref([])
const products = ref([])
const formdata = reactive({
  category: props.currentCategoryId,
  onlyDifferences: false,
})

const checkIsMobile = w => w < 1200

const prepareProducts = items => {
  return items.map(item => {
    item.attrs = item.attrs.reduce((acc, el) => {
      const keys = Object.keys(el)
      const [firstKey] = keys

      acc[firstKey] = el[firstKey]

      return acc
    }, {})

    return item
  })
}

const updateProductRowHeight = async (isMobile) => {
  const mobileRows = [...document.querySelectorAll('.js-product-rows')]
  const allRows = [
    ...document.querySelectorAll('.js-product-rows'),
    ...document.querySelectorAll('.js-nav-rows'),
  ]
  const productRows = isMobile ? mobileRows : allRows

  rowHeight.value = productRows.reduce((acc, el) => {
    if (isMobile) {
      el.style.height = 'auto'
    }

    const rowIndex = el.getAttribute('data-index')

    if (acc[rowIndex]) {
      if (el.offsetHeight > acc[rowIndex]) {
        acc[rowIndex] = el.offsetHeight
      }
    } else {
      acc[rowIndex] = el.offsetHeight
    }

    return acc
  }, {})
}

const calculateRowHeight = async (w) => {
  await nextTick()

  const isMobile = checkIsMobile(w)

  if (isMobile && scrollerContentElement.value) {
    scrollerScrollWidth.value = scrollerContentElement.value.scrollWidth
  }

  updateProductRowHeight(isMobile)
}

const getData = async () => {
  await nextTick()

  comparisonProductsResource.execute({}, formdata).then(async res => {
    const { data } = res

    const items = prepareProducts(data.items)

    products.value = items
    attributes.value = data.attrs

    await nextTick()

    if (!scroller.value) return

    scroller.value.checkScrollAvailability()

    calculateRowHeight(window.innerWidth)

    isLoading.value = false
  }).catch(() => {
    window.location = window.indexPageUrl
  })
}

watch(() => windowWidth.value, (nval) => {
  calculateRowHeight(nval)
})

watch(() => comparisonStore.comparisonInfo, () => {
  getData()
})

onMounted(() => {
  getData()
})

const __returned__ = { props, comparisonStore, windowWidth, isLoading, scroller, scrollerContentElement, scrollerScrollWidth, rowHeight, attributes, products, formdata, checkIsMobile, prepareProducts, updateProductRowHeight, calculateRowHeight, getData, ref, reactive, watch, onMounted, nextTick, get comparisonProductsResource() { return comparisonProductsResource }, get useComparisonStore() { return useComparisonStore }, get useWindowSize() { return useWindowSize } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})