import {
  getLocale,
  isNullOrUndefined,
  isEmptyArray,
  getSingleParam,
  isEmpty,
} from './utils'

import { i18n } from '@/i18n'

const { t: $t } = i18n.global

const emailErrMessage = $t('This field must be a valid email')
const requiredErrMessage = $t('This field is required')
const confirmedErrMessage = $t('Passwords must match')
const minErrMessage = $t('Min length is')
const minNoSpacesErrMessage = $t('Min value is')
const maxErrMessage = $t('Max length is')
const alphaErrMessage = $t('Only alpha characters')
const arrayLengthMaxErrMessage = $t('Array max langth is')
const customPasswordErrMessage = $t('Password error')
const maskedPhoneErrorMessage = $t('Phone is not valid')
const noSpacesMaxErrorMessage = $t('The entered value must not exceed')
const noSpacesMinErrorMessage = $t('The entered value must not be less')
const codeErrMessage = $t('Invalid code length')
const repeatSymbolsErrorMessage = $t('The maximum number of repeated characters is')
const urlErrorMessage = $t('This field must be a valid URL')
const emailOrPhoneMessage = $t('Enter a valid email or phone')

export const alpha: { [k: string]: RegExp } = {
  en: /^[A-Z\s]*$/i,
  cs: /^[A-ZÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ\s]*$/i,
  da: /^[A-ZÆØÅ\s]*$/i,
  de: /^[A-ZÄÖÜß\s]*$/i,
  es: /^[A-ZÁÉÍÑÓÚÜ\s]*$/i,
  fr: /^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ\s]*$/i,
  it: /^[A-Z\xC0-\xFF\s]*$/i,
  lt: /^[A-ZĄČĘĖĮŠŲŪŽ\s]*$/i,
  nl: /^[A-ZÉËÏÓÖÜ\s]*$/i,
  hu: /^[A-ZÁÉÍÓÖŐÚÜŰ\s]*$/i,
  pl: /^[A-ZĄĆĘŚŁŃÓŻŹ\s]*$/i,
  pt: /^[A-ZÃÁÀÂÇÉÊÍÕÓÔÚÜ\s]*$/i,
  ru: /^[А-ЯЁ\s]*$/i,
  sk: /^[A-ZÁÄČĎÉÍĹĽŇÓŔŠŤÚÝŽ\s]*$/i,
  sr: /^[A-ZČĆŽŠĐ\s]*$/i,
  sv: /^[A-ZÅÄÖ\s]*$/i,
  tr: /^[A-ZÇĞİıÖŞÜ\s]*$/i,
  uk: /^[А-ЩЬЮЯЄІЇҐ\s]*$/i,
}

const required = (value: unknown): boolean | string => {
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false || !value) {
    return requiredErrMessage
  }
  return true
}

const email = (value: any | string): boolean | string => {
  if (!value || !value.length) {
    return true
  }

  const EMAILREG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (!EMAILREG.test(value)) {
    return emailErrMessage
  }

  return true
}

const confirmed = (value: any | string, [target]: string): boolean | string => {
  if (value === target) {
    return true
  }
  return confirmedErrMessage
}

const min = (value: unknown, params: [string | number] | { length: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const length = Number(getSingleParam(params, 'length'))
  const preparedValue = String(value).length

  if (Array.isArray(value)) {
    return value.every(val => min(val, { length }))
  }

  if (preparedValue >= length) {
    return true
  } else {
    return `${minErrMessage} ${length}`
  }
}

const minNoSpaces = (value: unknown, params: [string | number] | { length: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const length = Number(getSingleParam(params, 'length'))
  const preparedValue = String(value).replace(/\s/g,'').length

  if (Array.isArray(value)) {
    return value.every(val => min(val, { length }))
  }

  if (preparedValue >= length) {
    return true
  } else {
    return `${minNoSpacesErrMessage} ${length}`
  }
}

const maskedPhone = (value: unknown, params: [string | number] | { length: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const length = Number(getSingleParam(params, 'length'))
  const preparedValue = String(value).replace(/[^\d]/g,'').length

  if (Array.isArray(value)) {
    return value.every(val => min(val, { length }))
  }

  if (preparedValue >= length) {
    return true
  } else {
    return maskedPhoneErrorMessage
  }
}

const max = (value: unknown, params: [string | number] | { length: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const length = Number(getSingleParam(params, 'length'))
  const preparedValue = String(value).length

  if (Array.isArray(value)) {
    return value.every(val => max(val, { length }))
  }

  if (preparedValue <= length) {
    return true
  } else {
    return `${maxErrMessage} ${length}`
  }
}

const alphaValidator = (value: unknown, params: [string | undefined] | { locale?: string }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }
  const locale = getLocale(params)
  if (Array.isArray(value)) {
    return value.every(val => alphaValidator(val, { locale }))
  }

  const valueAsString = String(value)
  const valueMatch = Object.keys(alpha).some(loc => alpha[loc].test(valueAsString))
  // Match at least one locale.
  if (!locale) {
    if (valueMatch) {
      return true
    } else {
      return alphaErrMessage
    }
  }
  return (alpha[locale] || alpha.en).test(valueAsString)
}

const arrayLengthMax = (value: unknown, params: [number | string] | { length: string | number }): boolean | string => {
  // Normalize the length value
  const length = Number(getSingleParam(params, 'length'))
  const val = value as ArrayLike<unknown>
  const preparedValue = val.length

  if (isNullOrUndefined(value)) {
    return false
  }

  if (!preparedValue) {
    value = Array.from(val)
  }

  if (preparedValue <= length) {
    return true
  } else {
    return `${arrayLengthMaxErrMessage} ${length}`
  }
}

const repeatSymbols = (value: any | string, params: [number | string]): boolean | string => {
  if (!value || !value.length) {
    return true
  }
  const length = Number(getSingleParam(params, 'repeatSymbols'))

  const REPEAT_SYMBOLS_VALIDATION = new RegExp(String.raw `(.)\1{${length}}`, 'g')

  if (REPEAT_SYMBOLS_VALIDATION.test(value)) {
    return `${repeatSymbolsErrorMessage} ${length}`
  }

  return true
}

const customPassword = (value: any | string): boolean | string => {
  if (!value || !value.length) {
    return true
  }

  const PASSWORD_VALIDATION = /(?=.*[#?!@$%^&*-])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.{8,14})(^[、_=\\[\];'",-/./~`!@#$%^&*()+|?><“:{}A-Za-z0-9]*$)/g

  if (PASSWORD_VALIDATION.test(value)) {
    return customPasswordErrMessage
  }

  return true
}

const maxValueValidatorNoSpaces = (value: string, params: [string | number] | { max: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const max = Number(getSingleParam(params, 'max'))
  const preparedValue = Number(value.replace(/ /g, ''))

  if (preparedValue <= max) {
    return true
  } else {
    return `${noSpacesMaxErrorMessage} ${max}`
  }
}

const minValueValidatorNoSpaces = (value: string, params: [string | number] | { max: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const min = Number(getSingleParam(params, 'min'))
  const preparedValue = Number(value.replace(/ /g, ''))

  if (preparedValue >= min) {
    return true
  } else {
    return `${noSpacesMinErrorMessage} ${min}`
  }
}

const code = (value: unknown, params: [string | number] | { length: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const length = Number(getSingleParam(params, 'length'))
  const preparedValue = String(value).length

  if (Array.isArray(value)) {
    return value.every(val => min(val, { length }))
  }

  if (preparedValue >= length) {
    return true
  } else {
    return codeErrMessage
  }
}

const url = (value: string): boolean | string => {
  const reg = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/

  const isValid = reg.test(value)

  if (isValid) {
    return true
  } else {
    return urlErrorMessage
  }
}

const emailOrPhone = (value: string): boolean | string => {
  const EMAILREG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const MOBILEREG = /^[+()-\d]{8,20}[0-9]+$/

  return (
    EMAILREG.test(value) ||
    MOBILEREG.test(value) ||
    emailOrPhoneMessage
  )
}

export {
  required,
  email,
  confirmed,
  min,
  max,
  alphaValidator,
  arrayLengthMax,
  customPassword,
  minNoSpaces,
  maskedPhone,
  maxValueValidatorNoSpaces,
  minValueValidatorNoSpaces,
  code,
  repeatSymbols,
  url,
  emailOrPhone,
}
