import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_select = _resolveComponent("control-select")!

  return (_openBlock(), _createBlock(_component_control_select, {
    modelValue: $setup.model,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event)),
      $setup.debounceUpdate
    ],
    "option-label": "title",
    name: $setup.props.filter.name,
    "track-by": $setup.props.config.label || 'label',
    multiple: $setup.props.config.multiple || false,
    searchable: $setup.props.config.multiple || false,
    "allow-empty": false,
    options: $setup.props.filter.values,
    "input-label": ""
  }, null, 8, ["modelValue", "name", "track-by", "multiple", "searchable", "options", "onUpdate:modelValue"]))
}