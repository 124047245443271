import { defineComponent as _defineComponent } from 'vue'
import { toRefs, defineAsyncComponent, onMounted } from 'vue'

import LoadingComponent from '@components/Modals/ModalLoading'
import ErrorComponent from '@components/Modals/ModalError'


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalWrapper',
  props: [
  'params',
  'close',
],
  emits: ['hook:created'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit

const props = __props
const { params, close } = toRefs(props)

const componentExtactor = defineAsyncComponent({
  loader: () => {
    return new Promise((resolve, reject) => {
      if (!params.value.component) {
        reject(true)

        return
      }
      (async function () {
        const res = await import(`@components/${params.value.component || 'Modals/ModalLoading'}`)

        resolve(res)
      })()
    })
  },
  delay: 1000,
  timeout: 5000,
  suspensible: false,
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  onError(error, retry, fail, attempts) {
    // Note that retry/fail is like resolve/reject in promise:
    // One of them must be called to continue error handling.
    if (attempts < 3) {
      // Try again when the request has an error. You can try up to 3 times
      retry()

      return
    }
    fail()
  },
})

onMounted(() => {
  emit('hook:created')

  if (params?.value.timeout) {
    setTimeout(() => {
      close?.value()
    }, params?.value.timeout)
  }
})

const __returned__ = { emit, props, params, close, componentExtactor, toRefs, defineAsyncComponent, onMounted, get LoadingComponent() { return LoadingComponent }, get ErrorComponent() { return ErrorComponent } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})