import { defineComponent as _defineComponent } from 'vue'
import { debounce } from 'lodash'
import { onMounted, ref, watch, nextTick } from 'vue'
import { useNotification } from '@kyvg/vue3-notification'
import useGlobal from '@composables/useGlobal'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiQuantity',
  props: {
  modelValue: {
    type: [String, Number],
    default: 1,
  },
  minCount: {
    type: Number,
    default: 1,
  },
  maxCount: {
    type: Number,
    default: 10000,
  },
  isLoad: {
    type: Boolean,
    default: false,
  },
  isDecimal: {
    type: Boolean,
    default: false,
  },
  divisibleCount: {
    type: [Number, String],
    default: 1,
  },
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit

const { notify } = useNotification()

const props = __props

const { $t } = useGlobal()

const debounceUpdate = ref(null)
const debounceEmitValue = ref(null)
const localCount = ref(1)

watch(() => props.modelValue, () => {
  const v = Number(props.modelValue)

  if (v > props.maxCount) {
    localCount.value = props.maxCount
  } else {
    localCount.value = v
  }
}, { immediate: true })

const counterDecrease = () => {
  if (props.isLoad) return

  /**
   * decrease value of counter
   */
  if (props.minCount < localCount.value) {
    if (props.isDecimal) {
      localCount.value = Number((Number(localCount.value) - props.minCount).toFixed(2))
    } else {
      localCount.value--
    }

    debounceEmitValue.value()
  }
}

const counterIncrease = () => {
  if (props.isLoad) return

  /**
   * inrease value of counter
   */
  if (props.isDecimal) {
    localCount.value = Number((Number(localCount.value) + props.minCount).toFixed(2))
  } else {
    localCount.value++
  }

  checkMaxCount()

  debounceEmitValue.value()
}

const blurEvent = () => {
  const zero = 0
  /**
   * reset counter if user don't write the value
   */
   const count = Number(localCount.value)

  if (count < props.minCount || (!count && count !== zero)) {
    resetCounter()
    emitValue()
  }
}

const updateEvent = () => {
  const zero = 0
  /**
   * reset counter if user don't write the value
   */
   const count = Number(localCount.value)

  if (count < props.minCount || (!count && count !== zero)) {
    resetCounter()
  } else if (localCount.value > zero) {
    checkMaxCount()
  }

  debounceEmitValue.value()
}

const checkMaxCount = () => {
  if (localCount.value > props.maxCount) {
    localCount.value = props.maxCount

    notify({
      text: `${$t('Максимальное количество товара доступное к заказу -')} ${props.maxCount}`,
      type: 'error',
    })
  }
}

const resetCounter = () => {
  localCount.value = props.minCount

  notify({
    text: `${$t('Минимальное количество товара доступное к заказу -')} ${props.minCount}`,
    type: 'error',
  })
}

const emitValue = async () => {
  /**
   * emit current value
   */
  await nextTick()

  const val = Number(localCount.value)
  const remainder = Number((val % props.minCount).toFixed(2))
  const valIsDivisible = remainder === 0

  if (props.isDecimal) {
    if (valIsDivisible) {
      emit('update:modelValue', (val).toFixed(2))
    } else {
      const updatedValue = val + (props.minCount - remainder)

      localCount.value = updatedValue

      emit('update:modelValue', (updatedValue).toFixed(2))
    }
  } else {
    const integerValue = Math.round(Number(val))

    localCount.value = integerValue

    emit('update:modelValue', integerValue)
  }
}

onMounted(() => {
  /**
   * add debounce wrapper for prevent multiple requests
  */
  debounceEmitValue.value = debounce(emitValue, 200)
  debounceUpdate.value = debounce(updateEvent, 1500)
})

const __returned__ = { emit, notify, props, $t, debounceUpdate, debounceEmitValue, localCount, counterDecrease, counterIncrease, blurEvent, updateEvent, checkMaxCount, resetCounter, emitValue, get debounce() { return debounce }, onMounted, ref, watch, nextTick, get useNotification() { return useNotification }, get useGlobal() { return useGlobal } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})