import { defineComponent as _defineComponent } from 'vue'
import { onMounted } from 'vue'
import { $vfm } from 'vue-final-modal'


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalWrapper',
  props: {
  info: {
    type: Object,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const openModal = () => {
  $vfm.show({
    component: 'UiModalContainer',
  }, {
    classes: ['modal--size_md'],
    component: 'Modals/Promo/PromoView',
    info: props.info,
  })
}

onMounted(() => {
  const alreadyViewed = localStorage.getItem('promo_modal') === 'viewed'

  if (alreadyViewed || !props.info.id) return

  setTimeout(() => {
    openModal()
  }, 3000)
})

const __returned__ = { props, openModal, onMounted, get $vfm() { return $vfm } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})