import { App } from 'vue'
import UiModalContainer from './ModalContainer.vue'
import UiSimpleModalsContainer from './SimpleContainer.vue'
import ModalTrigger from './ModalTrigger.vue'

const register = (app: App<Element>): void => {
  app
    .component('UiModalContainer', UiModalContainer)
    .component('UiSimpleModalContainer', UiSimpleModalsContainer)
    .component('ModalTrigger', ModalTrigger)
}

export default {
  register,
}
