import { defineComponent as _defineComponent } from 'vue'
import { blogCatalogResource } from '@/services/blog.service'


export default /*@__PURE__*/_defineComponent({
  __name: 'BlogCatalog',
  props: {
  pagination: {
    type: Object,
  },
  cardsWrapperClass: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const __returned__ = { props, get blogCatalogResource() { return blogCatalogResource } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})