import { App } from 'vue'

import ProductCard from './ProductCard.vue'
import ProductCardCompare from './ProductCardCompare.vue'
import ProductCatalog from './ProductCatalog.vue'
import ProductCatalogOutlet from './ProductCatalogOutlet.vue'
import ProductCatalogSearch from './ProductCatalogSearch.vue'
import ProductGallery from './ProductGallery.vue'
import ProductOrder from './ProductOrder.vue'

const register = (app: App<Element>): void => {
  app
    .component('product-card', ProductCard)
    .component('product-card-compare', ProductCardCompare)
    .component('product-catalog', ProductCatalog)
    .component('product-catalog-outlet', ProductCatalogOutlet)
    .component('product-catalog-search', ProductCatalogSearch)
    .component('product-gallery', ProductGallery)
    .component('product-order', ProductOrder)
}

export default {
  register,
}
