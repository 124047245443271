import { defineComponent as _defineComponent } from 'vue'
import { Navigation } from 'swiper'


export default /*@__PURE__*/_defineComponent({
  __name: 'SliderProducts',
  props: {
  nextEl: {
    type: String,
  },
  prevEl: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const modules = [Navigation]

const breakpoints = {
  0: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 3,
  },
  992: {
    slidesPerView: 4,
  },
  1440: {
    spaceBetween: 30,
    slidesPerView: 5,
  },
}

const __returned__ = { props, modules, breakpoints, get Navigation() { return Navigation } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})