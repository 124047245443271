import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue'
import ComponentExtactor from '@components/Modals/ModalWrapper'


export default /*@__PURE__*/_defineComponent({
  __name: 'SimpleContainer',
  setup(__props, { expose: __expose }) {
  __expose();

const local = ref(null)

const save = ({ component }) => {
  local.value = component
}

const __returned__ = { local, save, ref, get ComponentExtactor() { return ComponentExtactor } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})