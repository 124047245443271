import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "product-order" }
const _hoisted_2 = { class: "product-order__wrapper" }
const _hoisted_3 = { class: "product-order__price" }
const _hoisted_4 = {
  key: 0,
  class: "product-order__unit product-order__unit--width_auto"
}
const _hoisted_5 = {
  key: 1,
  class: "product-order__quantity"
}
const _hoisted_6 = { class: "product-order__field" }
const _hoisted_7 = { class: "ds-panel ds-panel--space_xs" }
const _hoisted_8 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_9 = { class: "ds-caption ds-caption--size_2xs ds-caption--appearance_center ds-caption--color_relief-4" }
const _hoisted_10 = {
  key: 2,
  class: "product-order__quantity"
}
const _hoisted_11 = { class: "product-order__field" }
const _hoisted_12 = {
  key: 0,
  class: "ds-panel ds-panel--space_xs"
}
const _hoisted_13 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_14 = { class: "ds-caption ds-caption--size_2xs ds-caption--appearance_center ds-caption--color_relief-4" }
const _hoisted_15 = {
  key: 3,
  class: "product-order__button"
}
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "control-button__element" }
const _hoisted_18 = { class: "control-button__icon" }
const _hoisted_19 = { class: "control-button__text" }
const _hoisted_20 = {
  key: 0,
  class: "product-order__total-wrapper"
}
const _hoisted_21 = { class: "product-order__total" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_quantity = _resolveComponent("ui-quantity")!
  const _component_icon_cart = _resolveComponent("icon-cart")!
  const _component_cart_button_add = _resolveComponent("cart-button-add")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "price")
      ]),
      (!$props.inStock)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "unit")
          ]))
        : _createCommentVNode("", true),
      ($props.inStock && $props.needConvertArea)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("Площадь в м²")), 1)
                ])
              ]),
              _createVNode(_component_ui_quantity, {
                class: "quantity-field--variant_1",
                modelValue: $setup.formdata.quantityInBox,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => (($setup.formdata.quantityInBox) = $event)),
                  $setup.quantityInBoxChangeHandler
                ],
                "min-count": $props.quantityInBox,
                "max-count": $setup.maxQuantityInBox,
                "is-decimal": true,
                "divisible-count": $setup.formdata.quantity
              }, null, 8, ["modelValue", "min-count", "divisible-count"])
            ])
          ]))
        : _createCommentVNode("", true),
      ($props.inStock)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              ($props.needConvertArea)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t("Упаковки")), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_ui_quantity, {
                class: "quantity-field--variant_1",
                modelValue: $setup.formdata.quantity,
                "onUpdate:modelValue": [
                  _cache[1] || (_cache[1] = ($event: any) => (($setup.formdata.quantity) = $event)),
                  $setup.quantityChangeHandler
                ],
                "max-count": $props.maxQuantity,
                "min-count": $props.minQuantity
              }, null, 8, ["modelValue", "max-count", "min-count"])
            ])
          ]))
        : _createCommentVNode("", true),
      ($props.inStock)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createVNode(_component_cart_button_add, {
              "item-id": $props.productId,
              type: $props.productType
            }, {
              default: _withCtx((props) => [
                _createElementVNode("div", {
                  class: "control-button control-button--variant_4 control-button--variant_5",
                  onClick: _withModifiers(($event: any) => (props.addToCart($setup.formdata.quantity)), ["prevent"])
                }, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_icon_cart)
                    ]),
                    _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t("Купить")), 1)
                  ])
                ], 8, _hoisted_16)
              ]),
              _: 1
            }, 8, ["item-id", "type"])
          ]))
        : _createCommentVNode("", true)
    ]),
    ($props.needConvertArea && $props.inStock)
      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("Загальна сума товара:")) + " " + _toDisplayString(_ctx.$filters.formatPrice($setup.totalPrice)) + " " + _toDisplayString(_ctx.$t("€")), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}