import { defineStore } from 'pinia'
import { likesRetrieveResource } from '@services/likes.service'

export const useLikesStore = defineStore('likes', {
  state: () => ({
    likes: {
      count: 0,
      isDeterminated: false,
    },
    lastAction: {
      added: null,
      removed: null,
    },
  }),

  getters: {
    likesInfo: state => state.likes,
    lastActionInfo: state => state.lastAction,
  },

  actions: {
    async getLikesData() {
      const { data: { item } } = await likesRetrieveResource.execute()

      this.setLikesInfo(item)
    },

    setLikesInfo(data: any) {
      this.likes = { ...data, isDeterminated: true }
    },

    setRemovedId(val: number | null) {
      this.lastAction.removed = val
    },

    setAddedId(val: number | null) {
      this.lastAction.added = val
    },
  },
})
