import { defineComponent as _defineComponent } from 'vue'
import {
  productsSearchCatalogResource,
  productsSearchCatalogCategoryResource,
} from '@/services/search.service'
import useGlobal from '@composables/useGlobal'


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductCatalogSearch',
  props: {
  pagination: {
    type: Object,
  },
  cardsWrapperClass: {
    type: String,
  },
  label: {
    type: String,
  },
  query: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const { $t } = useGlobal()

const pluralForms = [
  $t('товар'),
  $t('товара'),
  $t('товаров'),
]

// const sortValues = [
//   { title: $t('Сначала дорогие'), label: 'expensive' },
//   { title: $t('Сначала дешевые'), label: 'cheap' },
//   { title: $t('По новизне'), label: 'novelty' },
//   { title: $t('По рейтингу'), label: 'rank' },
// ]

const __returned__ = { props, $t, pluralForms, get productsSearchCatalogResource() { return productsSearchCatalogResource }, get productsSearchCatalogCategoryResource() { return productsSearchCatalogCategoryResource }, get useGlobal() { return useGlobal } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})