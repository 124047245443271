import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "order-product" }
const _hoisted_2 = { class: "order-product__wrapper" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "ds-aspect-ratio ds-aspect-ratio--appearance_filled ds-aspect-ratio--ratio_4x4" }
const _hoisted_5 = { class: "ds-aspect-ratio__body" }
const _hoisted_6 = {
  key: 0,
  class: "image-wrapper"
}
const _hoisted_7 = ["srcset"]
const _hoisted_8 = ["src", "alt", "title"]
const _hoisted_9 = {
  key: 1,
  class: "image-wrapper"
}
const _hoisted_10 = ["src", "alt", "title"]
const _hoisted_11 = { class: "order-product__main" }
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "order-product__variant" }
const _hoisted_14 = { class: "g-row g-row--appearance_spaced g-row--align_center" }
const _hoisted_15 = { class: "g-cell g-cols" }
const _hoisted_16 = { class: "g-row g-row--appearance_spaced g-row--align_center" }
const _hoisted_17 = { class: "g-cell g-cols" }
const _hoisted_18 = { class: "ds-caption ds-caption--size_2xxs ds-caption--size_xs-lg ds-caption--color_relief-5" }
const _hoisted_19 = { class: "g-cell g-cols" }
const _hoisted_20 = {
  key: 0,
  class: "color-circle color-circle--size_1"
}
const _hoisted_21 = { class: "color-circle__checkmark" }
const _hoisted_22 = { class: "color-circle__image" }
const _hoisted_23 = { class: "image-wrapper" }
const _hoisted_24 = ["src"]
const _hoisted_25 = {
  key: 1,
  class: "i-tag i-tag--variant_2 i-tag--variant_3 is-active"
}
const _hoisted_26 = { class: "order-product__total" }
const _hoisted_27 = { class: "g-row g-row--justify_between" }
const _hoisted_28 = { class: "g-cell" }
const _hoisted_29 = { class: "ds-caption ds-caption--size_2xxs ds-caption--color_relief-5" }
const _hoisted_30 = { class: "ds-caption ds-caption--size_3md ds-caption--weight_bold" }
const _hoisted_31 = { class: "g-cell" }
const _hoisted_32 = { class: "ds-caption ds-caption--size_2xxs ds-caption--color_relief-5" }
const _hoisted_33 = {
  key: 0,
  class: "ds-caption ds-caption--size_md ds-caption--color_relief-5"
}
const _hoisted_34 = {
  key: 1,
  class: "ds-caption ds-caption--size_md ds-caption--color_relief-5"
}
const _hoisted_35 = { class: "g-cell" }
const _hoisted_36 = { class: "ds-caption ds-caption--size_2xxs ds-caption--color_relief-5" }
const _hoisted_37 = { class: "ds-caption ds-caption--size_3md ds-caption--weight_bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_checkmark = _resolveComponent("icon-checkmark")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        class: "order-product__preview",
        href: $props.item.base.element.url
      }, [
        _createElementVNode("span", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            ($props.item.base.element.preview)
              ? (_openBlock(), _createElementBlock("picture", _hoisted_6, [
                  _createElementVNode("source", {
                    type: "image/webp",
                    srcset: $props.item.base.element.previewWebp
                  }, null, 8, _hoisted_7),
                  _createElementVNode("img", {
                    class: "image-wrapper__item image-wrapper__item--fit",
                    src: $props.item.base.element.preview,
                    alt: $props.item.base.element.name,
                    title: $props.item.base.element.name
                  }, null, 8, _hoisted_8)
                ]))
              : (_openBlock(), _createElementBlock("picture", _hoisted_9, [
                  _createElementVNode("img", {
                    class: "image-wrapper__item image-wrapper__item--fit",
                    src: $setup.placeholderImage,
                    alt: $props.item.base.element.name,
                    title: $props.item.base.element.name
                  }, null, 8, _hoisted_10)
                ]))
          ])
        ])
      ], 8, _hoisted_3),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("a", {
          class: "ds-link ds-link--size_2xs ds-link--color_dark ds-link--inline ds-link--weight_bold ds-link--hover_underline",
          href: $props.item.base.element.url
        }, _toDisplayString($props.item.base.element.name), 9, _hoisted_12),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.item.base.element.generalAttributes, (attr) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("p", _hoisted_18, _toDisplayString(attr.title), 1)
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    (attr.value.preview)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _createElementVNode("div", _hoisted_21, [
                            _createVNode(_component_icon_checkmark)
                          ]),
                          _createElementVNode("div", _hoisted_22, [
                            _createElementVNode("picture", _hoisted_23, [
                              _createElementVNode("img", {
                                class: "image-wrapper__item image-wrapper__item--fit",
                                src: attr.value.preview
                              }, null, 8, _hoisted_24)
                            ])
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(attr.value.title), 1))
                  ])
                ])
              ]))
            }), 256))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_26, [
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.$t("Цена")), 1),
          _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.$filters.formatPrice($props.item.base.price)) + " " + _toDisplayString(_ctx.$t("€")), 1)
        ]),
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("p", _hoisted_32, _toDisplayString(_ctx.$t("кол-во")), 1),
          ($props.item.base.convertArea)
            ? (_openBlock(), _createElementBlock("p", _hoisted_33, _toDisplayString($setup.totalArea) + " " + _toDisplayString(_ctx.$t("m2")), 1))
            : (_openBlock(), _createElementBlock("p", _hoisted_34, _toDisplayString($props.item.base.quantity), 1))
        ]),
        _createElementVNode("div", _hoisted_35, [
          _createElementVNode("p", _hoisted_36, _toDisplayString(_ctx.$t("Сумма")), 1),
          _createElementVNode("p", _hoisted_37, _toDisplayString(_ctx.$filters.formatPrice($props.item.price)) + " " + _toDisplayString(_ctx.$t("€")), 1)
        ])
      ])
    ])
  ]))
}