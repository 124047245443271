import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useSlots, useAttrs } from 'vue'
import { getVideoId, getVideoPreview } from '@utils/video.js'
import { $vfm } from 'vue-final-modal'

interface Props {
  component?: string,
  info?: object,
  videoUrl?: string,
  classes?: Array<string>,
  clickToClose?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalTrigger',
  props: {
    component: {},
    info: {},
    videoUrl: {},
    classes: {},
    clickToClose: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

const slots = useSlots()
const attrs = useAttrs()

const videoPreview = () => {
  if (props.videoUrl) {
    const videoId = getVideoId(props.videoUrl)
    const preview = getVideoPreview(videoId)

    return preview
  }
}

const openModal = () => {
  $vfm.show({
    component: 'UiModalContainer',
  }, {
    ...props,
    ...attrs,
  })
}

const render = () => slots.default({
  open: openModal,
  videoPreview: videoPreview(),
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(render))
}
}

})