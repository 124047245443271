import { defineComponent as _defineComponent } from 'vue'
import { ref, reactive, watch, onMounted } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ControlPhone',
  props: {
  codes: {
    type: Array,
    default: () => ([]),
  },
  value: {
    type: String,
  },
  predefinedCode: {
    type: Object,
  },
  openDirection: {
    type: String,
  },
  maxHeight: {
    type: [String, Number],
  },
},
  emits: ['update:modelValue', 'code:changed'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit
const model = ref(props.value)

const formdata = reactive({
  code: null,
})

const valueChangeHandler = (val: string) => {
  if (val) {
    const option = props.codes.find(el => val.startsWith(el.code))

    if (!option) return

    formdata.code = option

    emit('code:changed', option.code)
  }
}

const codeChanged = () => {
  emit('code:changed', formdata.code.code)
}

watch(() => props.value, (nval) => {
  model.value = nval

  valueChangeHandler(nval)
}, { immediate: true })

onMounted(() => {
  formdata.code = props.predefinedCode || props.codes[0]
})

const __returned__ = { props, emit, model, formdata, valueChangeHandler, codeChanged, ref, reactive, watch, onMounted }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})