import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "product-card__compare-actions" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "circle-icon__body circle-icon__body--size_2" }
const _hoisted_5 = { class: "product-card__image-wrapper" }
const _hoisted_6 = { class: "ds-aspect-ratio ds-aspect-ratio--appearance_filled ds-aspect-ratio--ratio_4x4" }
const _hoisted_7 = { class: "ds-aspect-ratio__body" }
const _hoisted_8 = {
  key: 0,
  class: "image-wrapper"
}
const _hoisted_9 = ["srcset"]
const _hoisted_10 = ["src", "alt", "title"]
const _hoisted_11 = {
  key: 1,
  class: "image-wrapper"
}
const _hoisted_12 = ["src", "alt", "title"]
const _hoisted_13 = { class: "product-card__main" }
const _hoisted_14 = { class: "product-card__content" }
const _hoisted_15 = { class: "product-card__title line-clamp line-clamp--line_2" }
const _hoisted_16 = ["title"]
const _hoisted_17 = { class: "product-card__footer" }
const _hoisted_18 = { class: "product-card__price" }
const _hoisted_19 = {
  key: 0,
  class: "ds-caption ds-caption--size_2xs ds-caption--color_relief-5 ds-caption--appearance_through"
}
const _hoisted_20 = { class: "ds-caption ds-caption--size_3md-xl ds-caption--weight_bold" }
const _hoisted_21 = { class: "product-card__button" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "control-button__icon control-button__icon--space_none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_trash = _resolveComponent("icon-trash")!
  const _component_compare_button_toggle = _resolveComponent("compare-button-toggle")!
  const _component_icon_cart = _resolveComponent("icon-cart")!
  const _component_cart_button_add = _resolveComponent("cart-button-add")!

  return (_openBlock(), _createElementBlock("a", {
    class: "product-card product-card--variant_compare-till-xl product-card--variant_horizontal-xl product-card--variant_2",
    href: $props.item.url
  }, [
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_compare_button_toggle, {
        "item-id": $props.item.id,
        "category-id": $props.item.category.id,
        "is-compared": true,
        "onCompare:changed": _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('compare:changed')))
      }, {
        default: _withCtx((props) => [
          _createElementVNode("span", {
            class: "circle-icon circle-icon--size_1",
            onClick: _withModifiers(props.toggle, ["prevent"])
          }, [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_icon_trash)
            ])
          ], 8, _hoisted_3)
        ]),
        _: 1
      }, 8, ["item-id", "category-id"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          ($props.item.preview)
            ? (_openBlock(), _createElementBlock("picture", _hoisted_8, [
                _createElementVNode("source", {
                  type: "image/webp",
                  srcset: $props.item.previewWebp
                }, null, 8, _hoisted_9),
                _createElementVNode("img", {
                  class: "image-wrapper__item",
                  src: $props.item.preview,
                  alt: $props.item.name,
                  title: $props.item.name
                }, null, 8, _hoisted_10)
              ]))
            : (_openBlock(), _createElementBlock("picture", _hoisted_11, [
                _createElementVNode("img", {
                  class: "image-wrapper__item image-wrapper__item--fit",
                  src: $setup.placeholderImage,
                  alt: $props.item.name,
                  title: $props.item.name
                }, null, 8, _hoisted_12)
              ]))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("p", {
            class: "ds-caption ds-caption--size_2xs ds-caption--size_sm-xl ds-caption--weight_bold",
            title: $props.item.name
          }, _toDisplayString($props.item.name), 9, _hoisted_16)
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          ($props.item.oldPrice)
            ? (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(_ctx.$filters.formatPrice($props.item.oldPrice)) + " " + _toDisplayString(_ctx.$t("€")), 1))
            : _createCommentVNode("", true),
          _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$filters.formatPrice($props.item.price)) + " " + _toDisplayString(_ctx.$t("€")) + "/" + _toDisplayString($props.item.unit), 1)
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createVNode(_component_cart_button_add, {
            "item-id": $props.item.id,
            type: $props.item.type
          }, {
            default: _withCtx((props) => [
              _createElementVNode("div", {
                class: "control-button control-button--variant_square control-button--variant_grey",
                onClick: _withModifiers(($event: any) => (props.addToCart()), ["prevent"])
              }, [
                _createElementVNode("div", _hoisted_23, [
                  _createVNode(_component_icon_cart)
                ])
              ], 8, _hoisted_22)
            ]),
            _: 1
          }, 8, ["item-id", "type"])
        ])
      ])
    ])
  ], 8, _hoisted_1))
}