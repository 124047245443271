import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue'
import { Autoplay, Navigation, Thumbs } from 'swiper'


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectGallery',
  props: {
  images: {
    type: Array,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const modules = [Autoplay, Navigation, Thumbs]

const thumbsSwiper = ref(null)

const setThumbsSwiper = (swiper: any) => {
  thumbsSwiper.value = swiper
}

const breakpoints = {
  320: {
    spaceBetween: 10,
  },
  1440: {
    spaceBetween: 30,
  },
}

const __returned__ = { props, modules, thumbsSwiper, setThumbsSwiper, breakpoints, ref, get Autoplay() { return Autoplay }, get Navigation() { return Navigation }, get Thumbs() { return Thumbs } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})