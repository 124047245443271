import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced g-row--space_lg g-row--space_2xl-xl" }
const _hoisted_2 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm" }
const _hoisted_3 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm" }
const _hoisted_4 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm" }
const _hoisted_5 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm" }
const _hoisted_6 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_7 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_8 = { class: "error-label" }
const _hoisted_9 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_10 = { class: "error-label" }
const _hoisted_11 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_12 = { class: "g-row g-row--justify_end" }
const _hoisted_13 = { class: "g-cell g-cols g-cols--12-xs g-cols--narrow-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_input = _resolveComponent("control-input")!
  const _component_control_select = _resolveComponent("control-select")!
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_control_button = _resolveComponent("control-button")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createBlock(_component_VeeForm, {
    class: "relative",
    onSubmit: $setup.send
  }, {
    default: _withCtx(({ errors }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["i-preloader", { 'is-active': $setup.isLoad }])
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("div", { class: "i-preloader__item" }, null, -1)
      ]), 2),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_control_input, {
            name: "name",
            type: "text",
            rules: "required|max:45",
            errors: errors,
            "input-label": $setup.$t("Имя"),
            modelValue: $setup.formdata.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formdata.name) = $event))
          }, null, 8, ["errors", "input-label", "modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_control_input, {
            name: "email",
            errors: errors,
            "input-label": $setup.$t("Эл. почта"),
            type: "email",
            rules: "required|email",
            modelValue: $setup.formdata.email,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formdata.email) = $event))
          }, null, 8, ["errors", "input-label", "modelValue"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          ($setup.choices.phoneCodes.length)
            ? (_openBlock(), _createBlock(_component_control_input, {
                key: 0,
                class: "control-input--variant_phone",
                component: "ControlPhone",
                name: "phone",
                "max-height": 190,
                errors: errors,
                "input-label": $setup.$t("Номер телефону"),
                codes: $setup.choices.phoneCodes,
                "predefined-code": $setup.predefinedPhoneCode,
                rules: "required|maskedPhone:8",
                modelValue: $setup.formdata.phone,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formdata.phone) = $event)),
                "onCode:changed": $setup.setCountryCode
              }, null, 8, ["errors", "input-label", "codes", "predefined-code", "modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_control_select, {
            modelValue: $setup.formdata.subject,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.formdata.subject) = $event)),
            "option-label": "title",
            name: "subject",
            rules: "",
            "allow-empty": true,
            options: $setup.choices.subject,
            errors: errors,
            "input-label": $setup.$t("Тип обращения"),
            "max-height": Boolean($props.close) ? 200 : 300
          }, null, 8, ["modelValue", "options", "errors", "input-label", "max-height"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_control_input, {
            component: "SimpleTextarea",
            name: "message",
            type: "text",
            rules: "required|min:5|max:1000",
            rows: "4",
            errors: errors,
            "input-label": $setup.$t("Сообщение"),
            modelValue: $setup.formdata.message,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.formdata.message) = $event))
          }, null, 8, ["errors", "input-label", "modelValue"])
        ]),
        _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
          default: _withCtx(({ message }) => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(message), 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_VeeError, { name: "captcha" }, {
          default: _withCtx(({ message }) => [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, _toDisplayString($setup.$t("ReCaptcha -")) + " " + _toDisplayString(message), 1)
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_control_button, {
                disabled: $setup.isLoad,
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.$t("Отправить")), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}