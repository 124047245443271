import { defineComponent as _defineComponent } from 'vue'
import { projectsCatalogResource } from '@/services/project.service'
import { prefixLanguage } from '@/utils/urls'


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectCatalog',
  props: {
  pagination: {
    type: Object,
  },
  cardsWrapperClass: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const urlPath = prefixLanguage('projects')

const __returned__ = { props, urlPath, get projectsCatalogResource() { return projectsCatalogResource }, get prefixLanguage() { return prefixLanguage } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})