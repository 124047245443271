import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue'
import useGlobal from '@composables/useGlobal'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiShare',
  props: {
  url: {
    type: String,
  },
  title: {
    type: String,
  },
  isModal: {
    type: Boolean,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const copyButton = ref(null)
const { $t } = useGlobal()

const networksForShare = [
  {
    network: 'facebook',
    title: $t('Facebook'),
    icon: 'icon-facebook',
    color: '#3b5998',
  },
  {
    network: 'telegram',
    title: $t('Telegram'),
    icon: 'icon-telegram',
    color: '#0088cc',
  },
  {
    network: 'viber',
    title: $t('Viber'),
    icon: 'icon-viber',
    color: '#7360f2',
  },
  {
    network: 'twitter',
    title: $t('Twitter'),
    icon: 'icon-twitter',
    color: '#1da1f2',
  },
  {
    network: 'linkedin',
    title: $t('Linkedin'),
    icon: 'icon-linkedin',
    color: '#0a66c2',
  },
  {
    network: 'whatsapp',
    title: $t('Whatsapp'),
    icon: 'icon-whatsapp',
    color: '#25d366',
  },
]

const __returned__ = { props, copyButton, $t, networksForShare, ref, get useGlobal() { return useGlobal } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})