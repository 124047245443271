import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_final_modal = _resolveComponent("vue-final-modal")!

  return (_openBlock(), _createBlock(_component_vue_final_modal, null, {
    default: _withCtx(({ close, params }) => [
      (params.component)
        ? (_openBlock(), _createBlock($setup["ComponentExtactor"], {
            key: 0,
            params: params,
            close: close,
            "component-path": $setup.local,
            "onHook:created": ($event: any) => ($setup.save(params))
          }, null, 8, ["params", "close", "component-path", "onHook:created"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}