import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "i-switch" }
const _hoisted_2 = { class: "i-switch__content" }
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        class: "i-switch__element",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event)),
        type: "checkbox",
        value: $setup.model,
        onChange: $setup.updateValue
      }, null, 40, _hoisted_3), [
        [_vModelCheckbox, $setup.model]
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "i-switch__slider" }, null, -1))
    ])
  ]))
}