import { App } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'

export default {
  install(app: App<Element>): any {
    // eslint-disable-next-line
    app.component('swiper', Swiper)
    app.component('swiper-slide', SwiperSlide)
  }
}
