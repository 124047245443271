import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue'

interface Props {
  tag?: string,
  variant?: string[] | any,
  size?: string[] | any,
  view?: string,
  disabled?: boolean,
  readonly?: boolean,
  invalid?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ControlButton',
  props: {
    tag: { default: 'button' },
    variant: { default: () => [] },
    size: { default: () => ['md'] },
    view: {},
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    invalid: { type: Boolean }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props

const getClasses = (arr: Array<string> = [], prefix: string) => {
  return arr.reduce<Record<string, boolean>>((acc, item) => {
    acc[`control-button--${prefix}_${item}`] = true

    return acc
  }, {})
}

const blockClasses = computed(() => {
  const sizesClass = getClasses(props.size, 'size')
  const variantClass = getClasses(props.variant, 'variant')

  return {
    ...sizesClass,
    ...variantClass,
    [`control-button--view_${props.view}`]: props.view,
    [`is-disabled`]: props.disabled,
    [`is-readonly`]: props.readonly,
    [`is-invalid`]: props.invalid,
  }
})

const __returned__ = { props, getClasses, blockClasses, computed }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})