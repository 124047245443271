import { defineComponent as _defineComponent } from 'vue'
import {
  collectionCatalogResource,
  collectionCatalogFiltersResource,
} from '@/services/collection.service'
import { prefixLanguage } from '@/utils/urls'
import useGlobal from '@composables/useGlobal'


export default /*@__PURE__*/_defineComponent({
  __name: 'CollectionCatalog',
  props: {
  pagination: {
    type: Object,
  },
  cardsWrapperClass: {
    type: String,
  },
  label: {
    type: String,
  },
  categories: {
    type: Array,
    default: () => ([]),
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const { $t } = useGlobal()

const pluralForms = [
  $t('колекция'),
  $t('колекции'),
  $t('колекций'),
]

const categoriesLabel = $t("Категории коллекций")

const sortValues = [
  { title: $t('Новинки'), label: 'novelty' },
  { title: $t('Популярные'), label: 'rank' },
]

const clearUrlPath = prefixLanguage('collections')
const urlPath = prefixLanguage(`${clearUrlPath}/${props.label}`)

const __returned__ = { props, $t, pluralForms, categoriesLabel, sortValues, clearUrlPath, urlPath, get collectionCatalogResource() { return collectionCatalogResource }, get collectionCatalogFiltersResource() { return collectionCatalogFiltersResource }, get prefixLanguage() { return prefixLanguage }, get useGlobal() { return useGlobal } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})