<template>
  <render ref="content" />
</template>

<script setup lang="ts">
import { ref, watch, useSlots } from 'vue'
import { useNotification } from '@kyvg/vue3-notification'
import { likesToggleResource } from '@services/likes.service'
import { useLikesStore } from '@/store/likes'
import useGlobal from '@composables/useGlobal'

const { $t } = useGlobal()
const { notify } = useNotification()

const props = defineProps({
  itemId: {
    type: [String, Number],
  },
  type: {
    type: String,
  },
  isLiked: {
    type: Boolean,
  },
})

const slots = useSlots() as any

const isLoad = ref(false)

const isLikedLocal = ref(props.isLiked)

const likesStore = useLikesStore()

watch(() => likesStore.lastActionInfo, (nval) => {
  if (Number(props.itemId) === nval.added) {
    isLikedLocal.value = true
  }

  if (Number(props.itemId) === nval.removed) {
    isLikedLocal.value = false
  }
}, { deep: true })

const showNotification = () => {
  if (isLikedLocal.value) {
    notify({ title: $t('Добавлено в избранное'), type: 'success' })
  } else {
    notify({ title: $t('Удалено из избранного'), type: 'success' })
  }
}

const toggle = () => {
  if (isLoad.value) return

  isLoad.value = true

  const productId = Number(props.itemId)

  const formdata = { id: props.itemId, type: props.type }

  likesToggleResource.execute({}, formdata).then(() => {
    isLikedLocal.value = !isLikedLocal.value

    if (isLikedLocal.value) {
      likesStore.setAddedId(productId)
      likesStore.setRemovedId(null)
    } else {
      likesStore.setRemovedId(productId)
      likesStore.setAddedId(null)
    }

    showNotification()

    likesStore.getLikesData()
  }).finally(() => {
    isLoad.value = false
  })
}

const render = () => slots.default({
  toggle,
  isLiked: isLikedLocal.value,
})
</script>
