import { defineComponent as _defineComponent } from 'vue'
import { ref, computed } from 'vue'

interface Props {
  type: string,
}

const p = 'password'
const t = 'text'


export default /*@__PURE__*/_defineComponent({
  __name: 'SimpleInput',
  props: {
    type: { default: 'text' }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props

const inputType = ref<string>(props.type)

const isPasswordField = computed(() => props.type === p)
const passwordIsVisible = computed(() => inputType.value === t)

const toggleType = () => {
  inputType.value = passwordIsVisible.value ? p : t
}

const __returned__ = { props, p, t, inputType, isPasswordField, passwordIsVisible, toggleType, ref, computed }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})