import {
  baseResource,
} from '@/resource/resource'
import { prefixLanguage } from '@/utils/urls'
import { friendlyUrlGenerator } from '@/utils/friendly'

const order = {
  path: [
    'prefix',
    'query',
    'postfix',
    'label',
    'filters',
    'page',
  ],
}

const LIST_URL_PREFIX = prefixLanguage('/search/')

export const newsSearchCatalogResource = friendlyUrlGenerator([
  LIST_URL_PREFIX, 'news/ajax'
], baseResource, order)

export const newsSearchCatalogCategoryResource = friendlyUrlGenerator([
  LIST_URL_PREFIX, 'news/ajax/category'
], baseResource, order)

export const collectionsSearchCatalogResource = friendlyUrlGenerator([
  LIST_URL_PREFIX, 'collections/ajax'
], baseResource, order)

export const collectionsSearchCatalogCategoryResource = friendlyUrlGenerator([
  LIST_URL_PREFIX, 'collections/ajax/category'
], baseResource, order)

export const projectsSearchCatalogResource = friendlyUrlGenerator([
  LIST_URL_PREFIX, 'projects/ajax'
], baseResource, order)

export const projectsSearchCatalogCategoryResource = friendlyUrlGenerator([
  LIST_URL_PREFIX, 'projects/ajax/category'
], baseResource, order)

export const productsSearchCatalogResource = friendlyUrlGenerator([
  LIST_URL_PREFIX, 'products/ajax'
], baseResource, order)

export const productsSearchCatalogCategoryResource = friendlyUrlGenerator([
  LIST_URL_PREFIX, 'products/ajax/category'
], baseResource, order)
