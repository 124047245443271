import { App } from 'vue'
import VueClipboard from 'vue3-clipboard'

export default {
  install(app: App<Element>): any {
    app.use(VueClipboard, {
      autoSetContainer: true,
      appendToBody: true,
    })
  }
}
