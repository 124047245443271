import { defineComponent as _defineComponent } from 'vue'
import { ref, watch } from 'vue'
import {
  likesCatalogResource,
  likesCatalogCollectionResource,
} from '@/services/likes.service'
import { prefixLanguage } from '@/utils/urls'
import { useLikesStore } from '@store/likes'


export default /*@__PURE__*/_defineComponent({
  __name: 'FavouriteCatalog',
  props: {
  pagination: {
    type: Object,
  },
  cardsWrapperClass: {
    type: String,
  },
  urlPath: {
    type: String,
  },
  catalogType: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const RECOURCE_MAP = {
  product: likesCatalogResource,
  collection: likesCatalogCollectionResource,
}

const props = __props

const isMounted = ref(false)

const list = ref(null)

const likesStore = useLikesStore()

watch(() => likesStore.likesInfo.count, () => {
  if (isMounted.value) {
    list.value.resetFilters()
  }

  isMounted.value = true
})

const __returned__ = { RECOURCE_MAP, props, isMounted, list, likesStore, ref, watch, get likesCatalogResource() { return likesCatalogResource }, get likesCatalogCollectionResource() { return likesCatalogCollectionResource }, get prefixLanguage() { return prefixLanguage }, get useLikesStore() { return useLikesStore } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})