import { $vfm } from 'vue-final-modal'

export const useModalOpener = (
  params: Record<string, unknown>,
  component = 'UiModalContainer',
): void => {
  $vfm.show({
    component,
  }, {
    ...params,
  })
}

export const useShowModal = (
  message: {
    title: string,
    text?: string,
  },
  params?: Record<string, unknown>,
  classes = [],
  component = 'Modals/UiMessageModal',
): void => {
  useModalOpener({
    message,
    ...params,
    classes,
    component,
  })
}

export function useConfirmWithPromise(
  message: {
    title: string,
    text?: string,
  },
  component = 'Modals/UiConfirmModal',
  classes = ['modal--space_1'],
): any {
  return new Promise(resolve => {
    const promise = (arg: boolean) => new Promise(res => {
      if (arg) {
        res(arg)
      }
    }).then(result => {
      resolve(result)
    })
    useModalOpener({
      message,
      classes,
      promise,
      component,
    })
  })
}
