import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue'
import { regionCountryChangeResource } from '@services/region.service'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiCountrySwitch',
  props: {
  countries: {
    type: Array,
    default: () => [],
  },
  currentCountryIsoCode: {
    type: [String, Number],
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const currentItem = computed(() => {
  return props.countries.find(el => (el.isoCode).toString() === (props.currentCountryIsoCode).toString())
})

const filteredItems = computed(() => {
  return props.countries.filter(el => (el.isoCode).toString() !== (props.currentCountryIsoCode).toString())
})

const changeCountry = (code: string) => {
  const params = { code }

  regionCountryChangeResource.execute(params).then(() => {
    window.location.reload()
  })
}

const __returned__ = { props, currentItem, filteredItems, changeCountry, computed, get regionCountryChangeResource() { return regionCountryChangeResource } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})