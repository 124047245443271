import {
  baseResource,
} from '@/resource/resource'
import { prefixLanguage } from '@/utils/urls'
import { friendlyUrlGenerator } from '@/utils/friendly'

// const MODEL_PREFIX = 'projects'

const FILTERS_LIST_URL_PREFIX = prefixLanguage('/projects')

export const projectsCatalogResource = friendlyUrlGenerator([
  FILTERS_LIST_URL_PREFIX, 'ajax'
], baseResource)
