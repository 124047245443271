import {
  createResource,
  postResource,
} from '@/resource/resource'
import { prefixAPI } from '@/resource/api'

const MODEL_PREFIX = 'region'

const REGION_COUNTRY_CHANGE = prefixAPI('/country-change/{code}/', MODEL_PREFIX)

export const regionCountryChangeResource = createResource(REGION_COUNTRY_CHANGE, postResource)
