import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "collection-gallery" }
const _hoisted_2 = {
  key: 0,
  class: "collection-gallery__main"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "collection-gallery__image-wrapper" }
const _hoisted_5 = ["srcset"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "gallery-item__plus" }
const _hoisted_8 = {
  key: 1,
  class: "collection-gallery__thumbs"
}
const _hoisted_9 = { class: "collection-gallery__navigation" }
const _hoisted_10 = { class: "collection-gallery__arrow collection-gallery__arrow--prev js-collection-gallery-prev" }
const _hoisted_11 = { class: "collection-gallery__arrow collection-gallery__arrow--next js-collection-gallery-next" }
const _hoisted_12 = { class: "collection-gallery__thumb-wrapper" }
const _hoisted_13 = ["srcset"]
const _hoisted_14 = ["src"]
const _hoisted_15 = {
  key: 1,
  class: "collection-gallery__video-icon"
}
const _hoisted_16 = {
  key: 2,
  class: "collection-gallery__placeholder"
}
const _hoisted_17 = { class: "ds-aspect-ratio ds-aspect-ratio--appearance_filled ds-aspect-ratio--ratio_4x4" }
const _hoisted_18 = { class: "ds-aspect-ratio__body" }
const _hoisted_19 = { class: "image-wrapper" }
const _hoisted_20 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_play = _resolveComponent("icon-play")!
  const _component_icon_plus_circle = _resolveComponent("icon-plus-circle")!
  const _component_modal_trigger = _resolveComponent("modal-trigger")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_icon_arrow_left = _resolveComponent("icon-arrow-left")!
  const _component_icon_arrow_right = _resolveComponent("icon-arrow-right")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.preparedImages.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_swiper, {
            modules: $setup.modules,
            loop: false,
            spaceBetween: 12,
            speed: 500,
            slidesPerView: 1,
            autoplay: { delay: 5000 },
            thumbs: { swiper: $setup.thumbsSwiper, autoScrollOffset: 1 },
            navigation: { nextEl: '.js-collection-gallery-next', prevEl: '.js-collection-gallery-prev' }
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.preparedImages, (item, index) => {
                return (_openBlock(), _createBlock(_component_swiper_slide, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_modal_trigger, {
                      component: "Modals/Common/ModalLightbox",
                      classes: ['modal--size_full'],
                      images: $setup.preparedImages,
                      "initial-slide": index
                    }, {
                      default: _withCtx(({ open }) => [
                        _createElementVNode("div", {
                          class: "gallery-item",
                          onClick: _withModifiers(open, ["prevent"])
                        }, [
                          _createElementVNode("picture", _hoisted_4, [
                            (item.image_url.webp)
                              ? (_openBlock(), _createElementBlock("source", {
                                  key: 0,
                                  type: "image/webp",
                                  srcset: item.image_url.webp
                                }, null, 8, _hoisted_5))
                              : _createCommentVNode("", true),
                            _createElementVNode("img", {
                              class: "collection-gallery__main-image",
                              src: item.image_url.base
                            }, null, 8, _hoisted_6)
                          ]),
                          _createElementVNode("div", _hoisted_7, [
                            (item.video_url)
                              ? (_openBlock(), _createBlock(_component_icon_play, { key: 0 }))
                              : (_openBlock(), _createBlock(_component_icon_plus_circle, { key: 1 }))
                          ])
                        ], 8, _hoisted_3)
                      ]),
                      _: 2
                    }, 1032, ["images", "initial-slide"])
                  ]),
                  _: 2
                }, 1024))
              }), 256))
            ]),
            _: 1
          }, 8, ["thumbs"])
        ]))
      : _createCommentVNode("", true),
    ($setup.preparedImages.length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_icon_arrow_left)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_icon_arrow_right)
            ])
          ]),
          _createVNode(_component_swiper, {
            onSwiper: $setup.setThumbsSwiper,
            modules: $setup.modules,
            loop: false,
            autoHeight: false,
            spaceBetween: 30,
            speed: 500,
            slidesPerView: 3,
            breakpoints: $setup.breakpoints
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.preparedImages, (item, index) => {
                return (_openBlock(), _createBlock(_component_swiper_slide, null, {
                  default: _withCtx(() => [
                    _createElementVNode("picture", _hoisted_12, [
                      (item.image_url.webp)
                        ? (_openBlock(), _createElementBlock("source", {
                            key: 0,
                            type: "image/webp",
                            srcset: item.image_url.webp
                          }, null, 8, _hoisted_13))
                        : _createCommentVNode("", true),
                      _createElementVNode("img", {
                        class: "collection-gallery__image",
                        src: item.image_url.base
                      }, null, 8, _hoisted_14),
                      (item.video_url)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            _createVNode(_component_icon_play)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 256))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (!$setup.preparedImages.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("picture", _hoisted_19, [
                _createElementVNode("img", {
                  class: "image-wrapper__item image-wrapper__item--fit",
                  src: $setup.placeholderImage
                }, null, 8, _hoisted_20)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}