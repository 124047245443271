<template lang="pug">
include /mixins.pug
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { $vfm } from 'vue-final-modal'

const props = defineProps({
  info: {
    type: Object,
  },
})

const openModal = () => {
  $vfm.show({
    component: 'UiModalContainer',
  }, {
    classes: ['modal--size_md'],
    component: 'Modals/Promo/PromoView',
    info: props.info,
  })
}

onMounted(() => {
  const alreadyViewed = localStorage.getItem('promo_modal') === 'viewed'

  if (alreadyViewed || !props.info.id) return

  setTimeout(() => {
    openModal()
  }, 3000)
})
</script>