import { App } from 'vue'

import CollectionCatalog from './CollectionCatalog.vue'
import CollectionCatalogSearch from './CollectionCatalogSearch.vue'
import CollectionGallery from './CollectionGallery.vue'

const register = (app: App<Element>): void => {
  app
    .component('collection-catalog', CollectionCatalog)
    .component('collection-catalog-search', CollectionCatalogSearch)
    .component('collection-gallery', CollectionGallery)
}

export default {
  register,
}
