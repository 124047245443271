import { App } from 'vue'
import SliderProducts from './SliderProducts.vue'
import SliderNews from './SliderNews.vue'
import SliderNewsAside from './SliderNewsAside.vue'
import SliderProductsNavigation from './SliderProductsNavigation.vue'
import SliderPersons from './SliderPersons.vue'

const register = (app: App<Element>): void => {
  app
    .component('slider-products', SliderProducts)
    .component('slider-news', SliderNews)
    .component('slider-news-aside', SliderNewsAside)
    .component('slider-products-navigation', SliderProductsNavigation)
    .component('slider-persons', SliderPersons)
}

export default {
  register,
}
