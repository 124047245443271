export function simplePluralize(n, textForms) {
  /* eslint-disable */
  const n1 = Number(n)
  const n2 = Number(n1) % 10

  if (n1 > 10 && n1 < 20) return textForms[2]
  if (n2 > 1 && n2 < 5) return textForms[1]
  if (n2 == 1) return textForms[0]

  return textForms[2]
}
