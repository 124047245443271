import {
  baseResource,
  createResource,
  postResource,
} from '@/resource/resource'
import { prefixAPI } from '@/resource/api'
import { prefixLanguage } from '@/utils/urls'
import { friendlyUrlGenerator } from '@/utils/friendly'

const MODEL_PREFIX = 'likes'

const LIKES_COUNT = prefixAPI('/count/', MODEL_PREFIX)
const LIKES_TOGGLE = prefixAPI('/toggle/', MODEL_PREFIX)

const FILTERS_LIST_URL_PREFIX = prefixLanguage('/favorites/ajax/')
const FILTERS_LIST_COLLECTION_URL_PREFIX = prefixLanguage('/favorites/ajax/collection/')

export const likesRetrieveResource = createResource(LIKES_COUNT)
export const likesToggleResource = createResource(LIKES_TOGGLE, postResource)

export const likesCatalogResource = friendlyUrlGenerator([
  FILTERS_LIST_URL_PREFIX, ''
], baseResource)

export const likesCatalogCollectionResource = friendlyUrlGenerator([
  FILTERS_LIST_COLLECTION_URL_PREFIX, ''
], baseResource)
