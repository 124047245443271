import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_view_search = _resolveComponent("list-view-search")!

  return (_openBlock(), _createBlock(_component_list_view_search, {
    pagination: $props.pagination,
    "list-resource": $props.label ? $setup.newsSearchCatalogCategoryResource : $setup.newsSearchCatalogResource,
    "cards-wrapper-class": $props.cardsWrapperClass,
    label: $props.label,
    query: $props.query,
    "plural-forms": $setup.pluralForms
  }, {
    heading: _withCtx(() => [
      _renderSlot(_ctx.$slots, "heading")
    ]),
    additional: _withCtx(() => [
      _renderSlot(_ctx.$slots, "additional")
    ]),
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "content")
    ]),
    _: 3
  }, 8, ["pagination", "list-resource", "cards-wrapper-class", "label", "query"]))
}