import { App } from 'vue'

import ButtonDetails from './ButtonDetails.vue'
import ButtonToggle from './ButtonToggle.vue'
import CompareView from './CompareView.vue'

const register = (app: App<Element>): void => {
  app
    .component('compare-button-details', ButtonDetails)
    .component('compare-button-toggle', ButtonToggle)
    .component('compare-view', CompareView)
}

export default {
  register,
}
