import {
  baseResource,
  createResource,
} from '@/resource/resource'
import { prefixAPI } from '@/resource/api'
import { prefixLanguage } from '@/utils/urls'
import { friendlyUrlGenerator } from '@/utils/friendly'

const COLLECTION_CATALOG_FILTERS = prefixAPI('/filter-attributes', 'collection')

const FILTERS_LIST_URL_PREFIX = prefixLanguage('/collections')

export const collectionCatalogResource = friendlyUrlGenerator([
  FILTERS_LIST_URL_PREFIX, 'ajax'
], baseResource)

export const collectionCatalogFiltersResource = friendlyUrlGenerator([
  COLLECTION_CATALOG_FILTERS, 'filters'
], baseResource)
