import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, renderList as _renderList, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ds-caption ds-caption--size_lg ds-caption--size_xl-md"
}
const _hoisted_2 = { class: "ds-panel ds-panel--space_xl" }
const _hoisted_3 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_4 = { class: "ds-caption ds-caption--size_2md ds-caption--size_lg-lg ds-caption--color_main ds-caption--weight_medium" }
const _hoisted_5 = { class: "g-row g-row--space_xl g-row--appearance_spaced" }
const _hoisted_6 = { class: "g-cell g-cols g-cols--6-xs g-cols--4-md g-cols--6-xl g-cols--4-hd" }
const _hoisted_7 = {
  key: 2,
  class: "compare"
}
const _hoisted_8 = { class: "compare__wrapper" }
const _hoisted_9 = { class: "compare__aside" }
const _hoisted_10 = { class: "compare__switch" }
const _hoisted_11 = { class: "g-row g-row--align_center" }
const _hoisted_12 = { class: "g-cell" }
const _hoisted_13 = { class: "g-cell" }
const _hoisted_14 = { class: "ds-caption ds-caption--size_sm ds-caption--color_relief-4" }
const _hoisted_15 = { class: "compare__attributes" }
const _hoisted_16 = { class: "i-table" }
const _hoisted_17 = { class: "i-table__body" }
const _hoisted_18 = ["data-index"]
const _hoisted_19 = { class: "i-table__cell" }
const _hoisted_20 = { class: "ds-caption ds-caption--size_sm" }
const _hoisted_21 = { class: "compare__content" }
const _hoisted_22 = {
  class: "compare__product-wrapper",
  ref: "scrollerContentElement"
}
const _hoisted_23 = { class: "compare__product" }
const _hoisted_24 = { class: "compare__attributes" }
const _hoisted_25 = { class: "i-table" }
const _hoisted_26 = { class: "i-table__body" }
const _hoisted_27 = ["data-index"]
const _hoisted_28 = { class: "i-table__cell" }
const _hoisted_29 = { class: "i-table__attr" }
const _hoisted_30 = { class: "ds-caption ds-caption--size_2xs" }
const _hoisted_31 = { class: "ds-caption ds-caption--size_2xs ds-caption--size_sm-xl ds-caption--color_relief-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_card_compare = _resolveComponent("product-card-compare")!
  const _component_control_switch = _resolveComponent("control-switch")!
  const _component_ui_table_scroller = _resolveComponent("ui-table-scroller")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$setup.products.length && !$setup.isLoading)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t("Нет товаров в сравнении.")), 1))
      : _createCommentVNode("", true),
    ($setup.products.length === 1)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("Недостаточно товаров для сравнения.")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_product_card_compare, {
                item: $setup.products[0],
                "onCompare:changed": $setup.getData
              }, null, 8, ["item"])
            ])
          ])
        ], 64))
      : _createCommentVNode("", true),
    ($setup.products.length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_control_switch, {
                      modelValue: $setup.formdata.onlyDifferences,
                      "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event: any) => (($setup.formdata.onlyDifferences) = $event)),
                        $setup.getData
                      ]
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("Только отличия")), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.attributes, (attr, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "i-table__row js-nav-rows",
                        "data-index": index,
                        style: _normalizeStyle({ 'height': `${$setup.rowHeight[index]}px` })
                      }, [
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("p", _hoisted_20, _toDisplayString(attr.title), 1)
                        ])
                      ], 12, _hoisted_18))
                    }), 256))
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createVNode(_component_ui_table_scroller, { ref: "scroller" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_22, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.products, (product, productIndex) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "compare__item",
                        key: 'product_' + product.id
                      }, [
                        _createElementVNode("div", _hoisted_23, [
                          _createVNode(_component_product_card_compare, {
                            item: product,
                            "onCompare:changed": $setup.getData
                          }, null, 8, ["item"])
                        ]),
                        _createElementVNode("div", _hoisted_24, [
                          _createElementVNode("div", _hoisted_25, [
                            _createElementVNode("div", _hoisted_26, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.attributes, (attr, index) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  class: "i-table__row js-product-rows",
                                  style: _normalizeStyle({ 'height': `${$setup.rowHeight[index]}px` }),
                                  "data-index": index
                                }, [
                                  _createElementVNode("div", _hoisted_28, [
                                    _createElementVNode("div", {
                                      class: _normalizeClass(["i-table__attr-wrapper", { 'is-active': !productIndex}]),
                                      style: _normalizeStyle({ width: `${$setup.scrollerScrollWidth}px` })
                                    }, [
                                      _createElementVNode("div", _hoisted_29, [
                                        _createElementVNode("p", _hoisted_30, _toDisplayString(attr.title), 1)
                                      ])
                                    ], 6),
                                    _createElementVNode("p", _hoisted_31, _toDisplayString(product.attrs[attr.label]), 1)
                                  ])
                                ], 12, _hoisted_27))
                              }), 256))
                            ])
                          ])
                        ])
                      ]))
                    }), 128))
                  ], 512)
                ]),
                _: 1
              }, 512)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}