<template>
  <render />
</template>

<script setup lang="ts">
import { ref, useSlots } from 'vue'

const slots = useSlots()

const isActive = ref(false)

const open = () => {
  const bodyEl = document.querySelector('body') as HTMLElement

  isActive.value = true

  bodyEl.classList.add('is-overflow')
}

const hide = () => {
  const bodyEl = document.querySelector('body') as HTMLElement

  isActive.value = false

  bodyEl.classList.remove('is-overflow')
}

const render = () => slots.default({
  isActive: isActive.value,
  open,
  hide,
})
</script>