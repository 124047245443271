import SelectWidget from './widgets/SelectWidget.vue'
import CheckboxWidget from './widgets/CheckboxWidget.vue'
import RangeWidget from './widgets/RangeWidget.vue'

export const WIDGETS_MAP = {
  'multiselect': {
    component: SelectWidget,
    config: {
      multiple: true,
    },
  },
  'select': {
    component: SelectWidget,
    config: {
      multiple: false,
    },
  },
  'checkbox': {
    component: CheckboxWidget,
  },
  'range': {
    component: RangeWidget,
  },
}
