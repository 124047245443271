import {
  createResource,
  postResource,
} from '@/resource/resource'
import { prefixAPI } from '@/resource/api'

const MODEL_PREFIX = 'comparison'

const COMPARISON_RETRIEVE = prefixAPI('/categories/', MODEL_PREFIX)
const COMPARISON_COUNT = prefixAPI('/count/', MODEL_PREFIX)
const COMPARISON_TOGGLE = prefixAPI('/toggle/', MODEL_PREFIX)
const COMPARISON_PRODUCTS = prefixAPI('/products/', MODEL_PREFIX)
const COMPARISON_CLEAR_CATEGORY = prefixAPI('/clear/{categoryId}/', MODEL_PREFIX)

export const comparisonRetrieveResource = createResource(COMPARISON_RETRIEVE)
export const comparisonCountResource = createResource(COMPARISON_COUNT)
export const comparisonToggleResource = createResource(COMPARISON_TOGGLE, postResource)
export const comparisonProductsResource = createResource(COMPARISON_PRODUCTS, postResource)
export const comparisonClearCategoryResource = createResource(COMPARISON_CLEAR_CATEGORY, postResource)
