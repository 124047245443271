import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ds-panel ds-panel--space_lg"
}
const _hoisted_2 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_3 = { class: "filter-scroller" }
const _hoisted_4 = ["name", "value"]
const _hoisted_5 = { class: "control-checkbox__content" }
const _hoisted_6 = {
  key: 0,
  class: "ds-caption ds-caption--size_sm ds-caption--color_relief-5"
}
const _hoisted_7 = {
  key: 1,
  class: "ds-caption ds-caption--size_sm ds-caption--color_relief-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_input = _resolveComponent("control-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($props.filter.values.length > 20)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_control_input, {
              modelValue: $setup.searchValue,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchValue) = $event)),
              type: "text",
              placeholder: $setup.placeholder,
              onInput: $setup.searchItems
            }, null, 8, ["modelValue", "placeholder"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.itemsFiltered, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "filter-scroller__item",
          key: item.label
        }, [
          _createElementVNode("label", {
            class: _normalizeClass(["control-checkbox control-checkbox--variant_flex", { 'is-disabled': !item.count && $props.dynamicFilters }])
          }, [
            _withDirectives(_createElementVNode("input", {
              class: "control-checkbox__element",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.model) = $event)),
              type: "checkbox",
              name: $props.filter.name,
              value: item.label,
              onChange: $setup.update
            }, null, 40, _hoisted_4), [
              [_vModelCheckbox, $setup.model]
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "control-checkbox__label" }, null, -1)),
            _createElementVNode("span", _hoisted_5, [
              ($props.dynamicFilters)
                ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(item.title) + " (" + _toDisplayString(item.count) + ")", 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(item.title), 1))
            ])
          ], 2)
        ]))
      }), 128))
    ])
  ]))
}