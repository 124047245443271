export const getLocale = (params?: { locale?: string } | [string | undefined]): string | undefined => {
  if (!params) {
    return undefined
  }

  return Array.isArray(params) ? params[0] : params.locale
}

export function isNullOrUndefined(value: unknown): value is undefined | null {
  return value === null || value === undefined
}

export function isEmptyArray(arr: unknown): boolean {
  return Array.isArray(arr) && arr.length === 0
}

export function getSingleParam<TParam = unknown>(params: [TParam] | Record<string, TParam>, paramName: string): unknown {
  return Array.isArray(params) ? params[0] : params[paramName]
}

export function isEmpty(value: unknown): boolean {
  if (value === null || value === undefined || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}