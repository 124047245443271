import { defineComponent as _defineComponent } from 'vue'
import { ref, reactive, inject, onMounted } from 'vue'
import { until } from '@vueuse/core'
import { useErrorsGetter, useParsedSetter } from '@components/Forms/FormMixin'
import {
  feedbackCreateResource,
  feedbackSubjectListResource,
} from '@services/feedback.service'
import { useShowModal } from '@composables/useModalOpener'
import usePhoneField from '@composables/usePhoneField'
import useGlobal from '@composables/useGlobal'


export default /*@__PURE__*/_defineComponent({
  __name: 'FormFeedbackCommon',
  props: {
  close: {
    type: Function,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const captchaConfig = inject('captchaConfig')

const { $t } = useGlobal()
const { getPhoneCodes, getPreselectedPhoneInfo } = usePhoneField()
const isLoad = ref(false)
const countryCode = ref()
const predefinedPhoneCode = ref()

const formdata = reactive({
  name: '',
  email: '',
  phone: '',
  subject: '',
  message: '',
  source: '',
  captcha: null,
})

const choices = reactive({
  subject: [],
  phoneCodes: [],
})

const getSubjectList = async () => {
  const { data: { items } } = await feedbackSubjectListResource.execute()

  choices.subject = items
}

const setCountryCode = (code: string) => {
  countryCode.value = code
}

const getDefaultSubject = () => {
  return choices.subject.find(item => item.isDefault) || null
}

const preselectSubject = () => {
  const defaultOption = getDefaultSubject()

  if (defaultOption) {
    formdata.subject = defaultOption
  }
}

const prepareFormdata = () => {
  const preparedFormdata = { ...formdata }

  if (preparedFormdata.phone && !preparedFormdata.phone.startsWith(countryCode.value)) {
    preparedFormdata.phone = `${countryCode.value}${preparedFormdata.phone}`
  }

  if (preparedFormdata.subject) {
    preparedFormdata.subject = preparedFormdata.subject.id
  }

  preparedFormdata.source = window.location.href

  return preparedFormdata
}

const send = async (sendData: object, control: any) => {
  control.setFieldError('nonFieldErrors', '')

  if (isLoad.value) return

  captchaConfig.execute()

  await until(captchaConfig.token).changed()

  captchaConfig.resetCaptcha()

  formdata.captcha = captchaConfig.token

  isLoad.value = true

  const preparedFormdata = prepareFormdata()

  feedbackCreateResource.execute({}, preparedFormdata).then(async () => {
    const message = {
      title: $t('Спасибо за запрос'),
      text: $t('Ваша заявка принята'),
    }

    useShowModal(message)

    if (props.close) {
      props.close()
    } else {
      control.setValues({
        email: '',
        name: '',
        phone: '',
        message: '',
        subject: getDefaultSubject(),
      })
      preselectSubject()
    }
  }).catch(async (e: object) => {
    const parsed = await useErrorsGetter(e)

    useParsedSetter(parsed, control.setFieldError)
  }).finally(() => {
    isLoad.value = false
  })
}

const getPhoneFieldData = async () => {
  choices.phoneCodes = await getPhoneCodes()

  const preselectedPhoneInfo = getPreselectedPhoneInfo(choices.phoneCodes)

  countryCode.value = preselectedPhoneInfo.countryCode
  predefinedPhoneCode.value = preselectedPhoneInfo.predefinedPhoneCode
}

onMounted(async () => {
  await getSubjectList()
  await getPhoneFieldData()

  preselectSubject()
})

const __returned__ = { props, captchaConfig, $t, getPhoneCodes, getPreselectedPhoneInfo, isLoad, countryCode, predefinedPhoneCode, formdata, choices, getSubjectList, setCountryCode, getDefaultSubject, preselectSubject, prepareFormdata, send, getPhoneFieldData, ref, reactive, inject, onMounted, get until() { return until }, get useErrorsGetter() { return useErrorsGetter }, get useParsedSetter() { return useParsedSetter }, get feedbackCreateResource() { return feedbackCreateResource }, get feedbackSubjectListResource() { return feedbackSubjectListResource }, get useShowModal() { return useShowModal }, get usePhoneField() { return usePhoneField }, get useGlobal() { return useGlobal } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})