<template>
  <render />
</template>

<script setup lang="ts">
import { useSlots } from 'vue'
import { $vfm } from 'vue-final-modal'
import { useCartStore } from '@store/cart'

const slots = useSlots()

const cartStore = useCartStore()

const openModal = () => {
  if (!cartStore.cartInfo.isDeterminated) return

  $vfm.show({
    component: 'UiModalContainer',
  }, {
    classes: cartStore.cartInfo.quantity ? ['modal--size_xl'] : ['modal--size_sm'],
    component: cartStore.cartInfo.quantity ? 'Modals/Cart/CartView' : 'Modals/Cart/CartEmpty',
  })
}

const render = () => slots.default({
  quantity: cartStore.cartInfo.quantity || 0,
  openModal,
  isDeterminated: cartStore.cartInfo.isDeterminated,
})
</script>
