<template>
  <render />
</template>

<script setup lang="ts">
import { ref, useSlots } from 'vue'

interface Props {
  initialActive?: boolean,
}

const slots = useSlots()
const props = defineProps<Props>()

const isActive = ref(props.initialActive || false)

const hide = () => {
  isActive.value = false
}

const show = () => {
  isActive.value = true
}

const toggle = () => {
  isActive.value = !isActive.value
}

const render = () => slots.default({
  isActive: isActive.value,
  hide,
  show,
  toggle,
})
</script>