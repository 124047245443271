import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "video-preview__wrapper" }
const _hoisted_2 = { class: "video-preview__image" }
const _hoisted_3 = {
  key: 0,
  class: "image-wrapper"
}
const _hoisted_4 = ["srcset"]
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "image-wrapper"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "video-preview__play" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_play = _resolveComponent("icon-play")!

  return (_openBlock(), _createElementBlock("div", {
    class: "video-preview",
    onClick: _withModifiers($setup.openVideoModal, ["prevent"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        ($props.previewBase)
          ? (_openBlock(), _createElementBlock("picture", _hoisted_3, [
              _createElementVNode("source", {
                type: "image/webp",
                srcset: $props.previewWebp
              }, null, 8, _hoisted_4),
              _createElementVNode("img", {
                class: "image-wrapper__item image-wrapper__item--fit",
                src: $props.previewBase
              }, null, 8, _hoisted_5)
            ]))
          : (_openBlock(), _createElementBlock("picture", _hoisted_6, [
              _createElementVNode("img", {
                class: "image-wrapper__item image-wrapper__item--fit",
                src: $setup.youtubePreview
              }, null, 8, _hoisted_7)
            ]))
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_icon_play)
      ])
    ])
  ]))
}