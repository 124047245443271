<template>
  <render ref="content" />
</template>

<script setup lang="ts">
import { ref, watch, useSlots } from 'vue'
import { useNotification } from '@kyvg/vue3-notification'
import { comparisonToggleResource } from '@services/comparison.service'
import { useComparisonStore } from '@/store/comparison'
import useGlobal from '@composables/useGlobal'

const emit = defineEmits(['compare:changed'])

const { $t } = useGlobal()
const { notify } = useNotification()

const comparisonStore = useComparisonStore()

const props = defineProps({
  itemId: {
    type: [String, Number],
  },
  isCompared: {
    type: Boolean,
  },
  categoryId: {
    type: Number,
  },
})

const slots = useSlots() as any

const isLoad = ref(false)

const isComparedLocal = ref(props.isCompared)

watch(() => comparisonStore.comparisonInfo, (nval) => {
  const hasCategoryInCompare = nval.items.find(item => item.id === props.categoryId)

  if (nval.isDeterminated && !hasCategoryInCompare && props.categoryId) {
    isComparedLocal.value = false
  }
}, { deep: true, immediate: true })

watch(() => comparisonStore.lastActionInfo, (nval) => {
  if (Number(props.itemId) === nval.added) {
    isComparedLocal.value = true
  }

  if (Number(props.itemId) === nval.removed) {
    isComparedLocal.value = false
  }
}, { deep: true })

const getComparisonInfo = async () => {
  await comparisonStore.getComparisonData()
  await comparisonStore.getComparisonCount()
}

const showNotification = () => {
  if (isComparedLocal.value) {
    notify({ title: $t('Товар добавлен в сравнение'), type: 'success' })
  } else {
    notify({ title: $t('Товар удалён из сравнения'), type: 'success' })
  }
}

const toggle = () => {
  if (isLoad.value) return

  isLoad.value = true

  const productId = Number(props.itemId)

  const formdata = { product: productId }

  comparisonToggleResource.execute({}, formdata).then(() => {
    isComparedLocal.value = !isComparedLocal.value

    if (isComparedLocal.value) {
      comparisonStore.setAddedId(productId)
      comparisonStore.setRemovedId(null)
    } else {
      comparisonStore.setRemovedId(productId)
      comparisonStore.setAddedId(null)
    }

    showNotification()

    getComparisonInfo()

    emit('compare:changed')
  }).finally(() => {
    isLoad.value = false
  })
}

const render = () => slots.default({
  toggle,
  isCompared: isComparedLocal.value,
})
</script>
