import { phoneCodeListResource } from '@services/feedback.service'

interface Result {
  send: Promise<void | boolean>;
  isLoad: boolean;
}

export default function (): Promise<Result> | any {
  const getPhoneCodes = async (): Promise<void> => {
    const { data: { items } } = await phoneCodeListResource.execute()

    return items
  }

  const getPreselectedPhoneInfo = (phoneCodes) => {
    const [firstOption] = phoneCodes
    const { defaultPhoneCode } = window

    if (defaultPhoneCode) {
      const option = phoneCodes.find(p => (p.id).toString() === (defaultPhoneCode).toString())
      const val = option ? option.code : firstOption.code

      return {
        countryCode: val,
        predefinedPhoneCode: option || firstOption,
      }
    }

    return {
      countryCode: firstOption.code,
      predefinedPhoneCode: firstOption,
    }
  }

  return { getPreselectedPhoneInfo, getPhoneCodes }
}