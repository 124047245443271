import {
  createResource,
  postResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'feedback'

const FEEDBACK_CREATE = prefixAPI('/create/', MODEL_PREFIX)
const FEEDBACK_SUBJECT_LIST = prefixAPI('/subject/list/', MODEL_PREFIX)

const PHONE_CODE_LIST = prefixAPI('/phone-code/list/', MODEL_PREFIX)

export const feedbackCreateResource = createResource(FEEDBACK_CREATE, postResource)
export const feedbackSubjectListResource = createResource(FEEDBACK_SUBJECT_LIST)

export const phoneCodeListResource = createResource(PHONE_CODE_LIST)
