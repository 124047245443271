import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ui-search" }
const _hoisted_2 = { class: "ui-search__field" }
const _hoisted_3 = { class: "control-descriptor" }
const _hoisted_4 = {
  key: 0,
  class: "ui-search__content"
}
const _hoisted_5 = {
  key: 0,
  class: "i-preloader i-preloader--absolute"
}
const _hoisted_6 = { class: "search-result" }
const _hoisted_7 = {
  key: 0,
  class: "search-result__wrapper"
}
const _hoisted_8 = {
  key: 0,
  class: "search-result__products"
}
const _hoisted_9 = { class: "g-row g-row--appearance_spaced" }
const _hoisted_10 = { class: "g-cell g-cols g-cols--12-xs g-cols--6-sm" }
const _hoisted_11 = {
  key: 0,
  class: "ds-panel ds-panel--space_2xl"
}
const _hoisted_12 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_13 = { class: "g-row" }
const _hoisted_14 = { class: "g-cell" }
const _hoisted_15 = ["href"]
const _hoisted_16 = { class: "i-link__text" }
const _hoisted_17 = { class: "i-link__icon i-link__icon--offset_left i-link__icon--size_2xl" }
const _hoisted_18 = { class: "search-result__additional" }
const _hoisted_19 = {
  key: 0,
  class: "search-result__item"
}
const _hoisted_20 = { class: "ds-panel ds-panel--space_xl" }
const _hoisted_21 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_22 = { class: "ds-caption ds-caption--size_lg ds-caption--weight_bold ds-caption--appearance_uppercase" }
const _hoisted_23 = { class: "g-row g-row--appearance_spaced g-row--space_md" }
const _hoisted_24 = { class: "g-cell g-cols g-cols--12-xs" }
const _hoisted_25 = ["href", "title"]
const _hoisted_26 = {
  key: 0,
  class: "ds-panel ds-panel--space_lg"
}
const _hoisted_27 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_28 = { class: "g-row" }
const _hoisted_29 = { class: "g-cell" }
const _hoisted_30 = ["href"]
const _hoisted_31 = { class: "i-link__text" }
const _hoisted_32 = { class: "i-link__icon i-link__icon--offset_left i-link__icon--size_2xl" }
const _hoisted_33 = {
  key: 1,
  class: "search-result__item"
}
const _hoisted_34 = { class: "ds-panel ds-panel--space_xl" }
const _hoisted_35 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_36 = { class: "ds-caption ds-caption--size_lg ds-caption--weight_bold ds-caption--appearance_uppercase" }
const _hoisted_37 = { class: "g-row g-row--appearance_spaced g-row--space_md" }
const _hoisted_38 = { class: "g-cell g-cols g-cols--12-xs" }
const _hoisted_39 = ["href"]
const _hoisted_40 = {
  key: 0,
  class: "ds-panel ds-panel--space_lg"
}
const _hoisted_41 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_42 = { class: "g-row" }
const _hoisted_43 = { class: "g-cell" }
const _hoisted_44 = ["href"]
const _hoisted_45 = { class: "i-link__text" }
const _hoisted_46 = { class: "i-link__icon i-link__icon--offset_left i-link__icon--size_2xl" }
const _hoisted_47 = {
  key: 2,
  class: "search-result__item search-result__item--variant_fullwidth"
}
const _hoisted_48 = { class: "ds-panel ds-panel--space_xl" }
const _hoisted_49 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_50 = { class: "ds-caption ds-caption--size_lg ds-caption--weight_bold ds-caption--appearance_uppercase" }
const _hoisted_51 = { class: "g-row g-row--appearance_spaced g-row--space_md" }
const _hoisted_52 = { class: "g-cell g-cols g-cols--12-xs" }
const _hoisted_53 = ["href"]
const _hoisted_54 = { class: "ds-panel ds-panel--space_xs ds-panel--space_sm-xl" }
const _hoisted_55 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_56 = { class: "ds-caption ds-caption--size_2xs ds-caption--color_relief-5" }
const _hoisted_57 = { class: "ds-caption ds-caption--size_2xs ds-caption--size_md-xl ds-caption--weight_medium" }
const _hoisted_58 = {
  key: 0,
  class: "ds-panel ds-panel--space_lg"
}
const _hoisted_59 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_60 = { class: "g-row" }
const _hoisted_61 = { class: "g-cell" }
const _hoisted_62 = ["href"]
const _hoisted_63 = { class: "i-link__text" }
const _hoisted_64 = { class: "i-link__icon i-link__icon--offset_left i-link__icon--size_2xl" }
const _hoisted_65 = {
  key: 1,
  class: "search-result__empty"
}
const _hoisted_66 = { class: "ds-caption ds-caption--size_sm ds-caption--size_3md-xl ds-caption--color_relief-4" }
const _hoisted_67 = {
  key: 2,
  class: "search-result__recommended"
}
const _hoisted_68 = { class: "ds-panel ds-panel--space_xl ds-panel--space_2xl-xl" }
const _hoisted_69 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_70 = { class: "ds-caption ds-caption--size_lg ds-caption--weight_bold ds-caption--appearance_uppercase" }
const _hoisted_71 = { class: "g-row g-row--appearance_spaced" }
const _hoisted_72 = { class: "g-cell g-cols g-cols--12-xs g-cols--6-sm g-cols--4-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_close = _resolveComponent("icon-close")!
  const _component_icon_search = _resolveComponent("icon-search")!
  const _component_control_input = _resolveComponent("control-input")!
  const _component_product_card = _resolveComponent("product-card")!
  const _component_icon_arrow_right_small = _resolveComponent("icon-arrow-right-small")!
  const _component_ui_click_outside = _resolveComponent("ui-click-outside")!

  return (_openBlock(), _createBlock(_component_ui_click_outside, { onTrigger: $setup.hide }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        ($setup.isFocused)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "ui-search__blur",
              onClick: $setup.hide
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["ui-search__wrapper", { 'is-active': $setup.isFocused }])
        }, [
          _createElementVNode("div", _hoisted_2, [
            ($setup.isFocused)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "ui-search__icon ui-search__icon--variant_close",
                  onClick: _withModifiers($setup.hide, ["prevent"])
                }, [
                  _createVNode(_component_icon_close)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["ui-search__icon ui-search__icon--variant_search", { 'is-active': $setup.isFocused }])
            }, [
              _createVNode(_component_icon_search)
            ], 2),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_control_input, {
                class: "control-input--variant_grey control-input--variant_search",
                modelValue: $setup.formdata.search,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formdata.search) = $event)),
                name: "search",
                ref: "search",
                type: "text",
                autocomplete: "off",
                placeholder: _ctx.$t("Я ищу"),
                onInput: _cache[1] || (_cache[1] = ($event: any) => {$setup.trimSearch();$setup.searchDebounceFn()}),
                onFocus: $setup.open
              }, null, 8, ["modelValue", "placeholder"])
            ])
          ]),
          ($setup.isFocused)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                ($setup.isLoading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[2] || (_cache[2] = [
                      _createElementVNode("div", { class: "i-preloader__item i-preloader__item--size_md" }, null, -1)
                    ])))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_6, [
                  (!$setup.recommendedProducts.length && ($setup.result.products.items.length || $setup.result.collections.items.length || $setup.result.projects.items.length || $setup.result.news.items.length))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        ($setup.result.products.items.length)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createElementVNode("div", _hoisted_9, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.result.products.items, (item) => {
                                  return (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                    _createVNode(_component_product_card, {
                                      class: "product-card--variant_1",
                                      item: item
                                    }, null, 8, ["item"])
                                  ]))
                                }), 256))
                              ]),
                              ($setup.result.products.linkToAll)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                    _createElementVNode("div", _hoisted_12, [
                                      _createElementVNode("div", _hoisted_13, [
                                        _createElementVNode("div", _hoisted_14, [
                                          _createElementVNode("a", {
                                            class: "i-link i-link--size_2xs i-link--size_sm-xl i-link--color_main",
                                            href: $setup.result.products.linkToAll
                                          }, [
                                            _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("Просмотреть все товары")), 1),
                                            _createElementVNode("span", _hoisted_17, [
                                              _createVNode(_component_icon_arrow_right_small)
                                            ])
                                          ], 8, _hoisted_15)
                                        ])
                                      ])
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_18, [
                          ($setup.result.collections.items.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                _createElementVNode("div", _hoisted_20, [
                                  _createElementVNode("div", _hoisted_21, [
                                    _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.$t("Коллекции")), 1)
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_23, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.result.collections.items, (item) => {
                                    return (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                      _createElementVNode("a", {
                                        class: "ds-link ds-link--color_dark ds-link--size_2xs ds-link--size_sm-xl ds-link--inline",
                                        href: item.url,
                                        title: item.name
                                      }, _toDisplayString(item.name), 9, _hoisted_25)
                                    ]))
                                  }), 256))
                                ]),
                                ($setup.result.collections.linkToAll)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                      _createElementVNode("div", _hoisted_27, [
                                        _createElementVNode("div", _hoisted_28, [
                                          _createElementVNode("div", _hoisted_29, [
                                            _createElementVNode("a", {
                                              class: "i-link i-link--size_2xs i-link--size_sm-xl i-link--color_main",
                                              href: $setup.result.collections.linkToAll
                                            }, [
                                              _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.$t("Все коллекции")), 1),
                                              _createElementVNode("span", _hoisted_32, [
                                                _createVNode(_component_icon_arrow_right_small)
                                              ])
                                            ], 8, _hoisted_30)
                                          ])
                                        ])
                                      ])
                                    ]))
                                  : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true),
                          ($setup.result.projects.items.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                _createElementVNode("div", _hoisted_34, [
                                  _createElementVNode("div", _hoisted_35, [
                                    _createElementVNode("p", _hoisted_36, _toDisplayString(_ctx.$t("Проекты")), 1)
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_37, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.result.projects.items, (item) => {
                                    return (_openBlock(), _createElementBlock("div", _hoisted_38, [
                                      _createElementVNode("a", {
                                        class: "ds-link ds-link--color_dark ds-link--size_2xs ds-link--size_sm-xl ds-link--inline",
                                        href: item.url
                                      }, _toDisplayString(item.title), 9, _hoisted_39)
                                    ]))
                                  }), 256))
                                ]),
                                ($setup.result.projects.linkToAll)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                                      _createElementVNode("div", _hoisted_41, [
                                        _createElementVNode("div", _hoisted_42, [
                                          _createElementVNode("div", _hoisted_43, [
                                            _createElementVNode("a", {
                                              class: "i-link i-link--size_2xs i-link--size_sm-xl i-link--color_main",
                                              href: $setup.result.projects.linkToAll
                                            }, [
                                              _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.$t("Все проекты")), 1),
                                              _createElementVNode("span", _hoisted_46, [
                                                _createVNode(_component_icon_arrow_right_small)
                                              ])
                                            ], 8, _hoisted_44)
                                          ])
                                        ])
                                      ])
                                    ]))
                                  : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true),
                          ($setup.result.news.items.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                                _createElementVNode("div", _hoisted_48, [
                                  _createElementVNode("div", _hoisted_49, [
                                    _createElementVNode("p", _hoisted_50, _toDisplayString(_ctx.$t("Новости")), 1)
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_51, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.result.news.items, (item) => {
                                    return (_openBlock(), _createElementBlock("div", _hoisted_52, [
                                      _createElementVNode("a", {
                                        href: item.url
                                      }, [
                                        _createElementVNode("div", _hoisted_54, [
                                          _createElementVNode("div", _hoisted_55, [
                                            _createElementVNode("p", _hoisted_56, _toDisplayString(item.date), 1)
                                          ])
                                        ]),
                                        _createElementVNode("p", _hoisted_57, _toDisplayString(item.title), 1)
                                      ], 8, _hoisted_53)
                                    ]))
                                  }), 256))
                                ]),
                                ($setup.result.news.linkToAll)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_58, [
                                      _createElementVNode("div", _hoisted_59, [
                                        _createElementVNode("div", _hoisted_60, [
                                          _createElementVNode("div", _hoisted_61, [
                                            _createElementVNode("a", {
                                              class: "i-link i-link--size_2xs i-link--size_sm-xl i-link--color_main",
                                              href: $setup.result.news.linkToAll
                                            }, [
                                              _createElementVNode("span", _hoisted_63, _toDisplayString(_ctx.$t("Все новости")), 1),
                                              _createElementVNode("span", _hoisted_64, [
                                                _createVNode(_component_icon_arrow_right_small)
                                              ])
                                            ], 8, _hoisted_62)
                                          ])
                                        ])
                                      ])
                                    ]))
                                  : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  ($setup.recommendedProducts.length && $setup.isSearch && !$setup.isLoading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                        _createElementVNode("p", _hoisted_66, _toDisplayString(_ctx.$t("По вашему запросу результатов не найдено.")), 1)
                      ]))
                    : _createCommentVNode("", true),
                  ($setup.recommendedProducts.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
                        _createElementVNode("div", _hoisted_68, [
                          _createElementVNode("div", _hoisted_69, [
                            _createElementVNode("p", _hoisted_70, _toDisplayString(_ctx.$t("Рекомендуемые товары")), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_71, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.recommendedProducts, (item) => {
                            return (_openBlock(), _createElementBlock("div", _hoisted_72, [
                              _createVNode(_component_product_card, {
                                class: "product-card--variant_1",
                                item: item
                              }, null, 8, ["item"])
                            ]))
                          }), 256))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ])
    ]),
    _: 1
  }))
}