import { defineComponent as _defineComponent } from 'vue'
import { ref, watch, computed, reactive, onMounted } from 'vue'
import { debounce } from 'lodash'
import { $vfm } from 'vue-final-modal'
import { useShowModal } from '@composables/useModalOpener'
import { phoneCodeListResource } from '@services/feedback.service'
import { useErrorsGetter, useParsedSetter } from '@components/Forms/FormMixin'
import {
  orderCreateResource,
  orderCheckVatNumberResource,
  orderCalculateResource,
} from '@services/order.service'
import { useCartStore } from '@store/cart'
import usePhoneField from '@composables/usePhoneField'
import useGlobal from '@/composables/useGlobal'

import CheckoutDelivery from '@components/Checkout/CheckoutDelivery'
import CheckoutIndividualEntity from '@components/Checkout/CheckoutIndividualEntity'
import CheckoutLegalEntity from '@components/Checkout/CheckoutLegalEntity'
import CheckoutSummary from '@components/Checkout/CheckoutSummary'
import CheckoutPayment from '@components/Checkout/CheckoutPayment'


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutView',
  props: {
  countries: {
    type: Array,
    default: () => [],
  },
  currentCountryIsoCode: {
    type: [String, Number],
  },
  memberStates: {
    type: Array,
    default: () => [],
  },
  payments: {
    type: Array,
    default: () => [],
  },
  deliveryWays: {
    type: Array,
    default: () => [],
  },
  pickupAddresses: {
    type: Array,
    default: () => [],
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const { $t } = useGlobal()

const CLIENT_TYPES = {
  individual: 'fiz',
  legal: 'jur',
}

const choices = reactive({
  clientType: [
    { title: $t('Individual'), label: CLIENT_TYPES.individual },
    { title: $t('Legal'), label: CLIENT_TYPES.legal },
  ],
  phoneCodes: [],
  payments: props.payments,
  deliveryWays: props.deliveryWays,
  memberStates: props.memberStates,
  pickupAddresses: props.pickupAddresses,
})

const cartStore = useCartStore()

const { getPhoneCodes, getPreselectedPhoneInfo } = usePhoneField()

const isLoad = ref(false)
const countryCode = ref()
const predefinedPhoneCode = ref()
const localCart = ref([])
const calculateDeliveryDebounce = ref(null)
const priceInfo = ref({
  deliverySum: 0,
  priceWithoutVat: 0,
  totalPrice: 0,
  vat: 0,
  vatSum: 0,
  totalWeigh: 0,
})

const formdata = reactive({
  clientType: null,
  acceptPolicy: false,
  paymentWay: null,
  deliveryWay: null,
  pickupAddress: null,
  postcode: '',
  legal: {
    vatNumber: null,
  },
  individual: {},
})

const currentCountry = computed(() => {
  const code = (props.currentCountryIsoCode).toString()
  const option = props.countries.find(el => (el.isoCode).toString() === code)

  return option.title
})

const isIndividualClient = computed(() => {
  return formdata.clientType && formdata.clientType.label === CLIENT_TYPES.individual
})

const isLegalClient = computed(() => {
  return formdata.clientType && formdata.clientType.label === CLIENT_TYPES.legal
})

watch(() => cartStore.cartInfo, (nval) => {
  if (nval.isDeterminated) {
    if (!nval.groups.length) {
      window.location = window.indexPageUrl
    } else {
      localCart.value = JSON.parse(JSON.stringify(nval))
    }
  }
}, { immediate: true })

const setCountryCode = (code: string) => {
  countryCode.value = code
}

const getPhoneFieldData = async () => {
  choices.phoneCodes = await getPhoneCodes()

  const preselectedPhoneInfo = getPreselectedPhoneInfo(choices.phoneCodes)

  countryCode.value = preselectedPhoneInfo.countryCode
  predefinedPhoneCode.value = preselectedPhoneInfo.predefinedPhoneCode
}

const preselectClientType = () => {
  const defaultOption = choices.clientType.find(el => el.label === CLIENT_TYPES.individual)

  if (defaultOption) {
    formdata.clientType = defaultOption
  }
}

const preselectDeliveryWay = () => {
  const defaultOption = choices.deliveryWays.find(el => el.service === 'courier')

  formdata.deliveryWay = defaultOption
}

const preselectDeliveryPickup = () => {
  if (choices.pickupAddresses.length === 1) {
    formdata.pickupAddress = choices.pickupAddresses[0]
  }
}

const postalCodeChanged = (setErrors) => {
  setErrors({ postcodeCustom: null })

  calculateDeliveryDebounce.value(setErrors)
}

const calculateDelivery = (setErrors = null) => {
  const postalcode = formdata.postcode ? formdata.postcode.trimStart().replace(/\s\s+/g, ' ') : null
  const preparedFormdata = { postalcode, deliveryWay: formdata.deliveryWay?.service }

  orderCalculateResource.execute({}, preparedFormdata).then(res => {
    const { data: { item } } = res

    priceInfo.value = item

    if (!setErrors) return

    if (postalcode && item.postCodeError) {
      setErrors({ postcodeCustom: item.postCodeError })
    }
  })
}

const showOrderErrorModal = () => {
  const message = {
    title: $t('Заказ не создан'),
    text: $t('Проверьте правильность заполнения формы'),
  }

  useShowModal(message)
}

const validateVatNumber = (control) => {
  return new Promise((resolve) => {
    const preparedFormdata = {
      memberState: formdata.legal.memberState.value,
      vatNumber: formdata.legal.vatNumber,
    }

    orderCheckVatNumberResource.execute({}, preparedFormdata).then(res => {
      const { data: { item } } = res

      if (item.isValid) {
        resolve(true)
      } else {
        const message = {
          title: $t('НДС номер не валидный'),
          text: $t('Исправьте НДС номер или обратитесь к администратору сайта'),
        }
        useShowModal(message)
      }
    }).catch(async (e: object) => {
      const parsed = await useErrorsGetter(e)

      useParsedSetter(parsed, control.setFieldError)

      showOrderErrorModal()
    })
  })
}

const prepareFormdata = () => {
  const copiedFormdata = JSON.parse(JSON.stringify(formdata))
  const legalInfo = { ...copiedFormdata, ...copiedFormdata.legal }
  const individualInfo = { ...copiedFormdata, ...copiedFormdata.individual }

  const preparedFormdata = isLegalClient.value ? legalInfo : individualInfo

  delete preparedFormdata.individual
  delete preparedFormdata.legal
  delete preparedFormdata.memberState

  if (preparedFormdata.phone && !preparedFormdata.phone.startsWith(countryCode.value)) {
    preparedFormdata.phone = `${countryCode.value}${preparedFormdata.phone}`
  }

  preparedFormdata.paymentWay = choices.payments[0].id
  preparedFormdata.clientType = preparedFormdata.clientType.label

  const deliveryWay = preparedFormdata.deliveryWay.service

  preparedFormdata.deliveryWay = deliveryWay

  if (deliveryWay === 'pickup') {
    delete preparedFormdata.postcode
    delete preparedFormdata.city
    delete preparedFormdata.street
    delete preparedFormdata.streetNo
    delete preparedFormdata.apartment

    preparedFormdata.pickupAddress = preparedFormdata.pickupAddress.id
  } else {
    delete preparedFormdata.pickupAddress
  }

  return preparedFormdata
}

const send = async (sendData: object, control: any) => {
  control.setFieldError('nonFieldErrors', '')

  if (isLoad.value) return

  isLoad.value = true

  const preparedFormdata = prepareFormdata()

  orderCreateResource.execute({}, preparedFormdata).then(res => {
    const { data: { item } } = res

    window.location = item.url
  }).catch(async (e: object) => {
    const parsed = await useErrorsGetter(e)

    useParsedSetter(parsed, control.setFieldError)

    if (parsed.paymentFailed) {
      const message = {
        title: $t('Ошибка платежной системы'),
        text: $t('Обратитесь к администротору сайта'),
      }

      $vfm.show({
        component: 'UiModalContainer',
      }, {
        component: 'Modals/PaymentErrorModal',
        redirectUrl: window.indexPageUrl,
        message,
      })
    } else {
      showOrderErrorModal()
    }
  }).finally(() => {
    isLoad.value = false
  })
}

const validateBeforeSubmit = async (sendData: object, control: any) => {
  if (isLegalClient.value && formdata.legal.vatNumber) {
    try {
      await validateVatNumber(control)

      send(sendData, control)
    } catch (e) {
      console.log(e)
    }
  } else {
    send(sendData, control)
  }
}

onMounted(async () => {
  calculateDelivery()

  await getPhoneFieldData()

  preselectClientType()
  preselectDeliveryWay()
  preselectDeliveryPickup()

  calculateDeliveryDebounce.value = debounce(calculateDelivery, 800)
})

const __returned__ = { props, $t, CLIENT_TYPES, choices, cartStore, getPhoneCodes, getPreselectedPhoneInfo, isLoad, countryCode, predefinedPhoneCode, localCart, calculateDeliveryDebounce, priceInfo, formdata, currentCountry, isIndividualClient, isLegalClient, setCountryCode, getPhoneFieldData, preselectClientType, preselectDeliveryWay, preselectDeliveryPickup, postalCodeChanged, calculateDelivery, showOrderErrorModal, validateVatNumber, prepareFormdata, send, validateBeforeSubmit, ref, watch, computed, reactive, onMounted, get debounce() { return debounce }, get $vfm() { return $vfm }, get useShowModal() { return useShowModal }, get phoneCodeListResource() { return phoneCodeListResource }, get useErrorsGetter() { return useErrorsGetter }, get useParsedSetter() { return useParsedSetter }, get orderCreateResource() { return orderCreateResource }, get orderCheckVatNumberResource() { return orderCheckVatNumberResource }, get orderCalculateResource() { return orderCalculateResource }, get useCartStore() { return useCartStore }, get usePhoneField() { return usePhoneField }, get useGlobal() { return useGlobal }, get CheckoutDelivery() { return CheckoutDelivery }, get CheckoutIndividualEntity() { return CheckoutIndividualEntity }, get CheckoutLegalEntity() { return CheckoutLegalEntity }, get CheckoutSummary() { return CheckoutSummary }, get CheckoutPayment() { return CheckoutPayment } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})