import { defineComponent as _defineComponent } from 'vue'
import {
  newsSearchCatalogResource,
  newsSearchCatalogCategoryResource,
} from '@/services/search.service'
import useGlobal from '@composables/useGlobal'


export default /*@__PURE__*/_defineComponent({
  __name: 'BlogCatalogSearch',
  props: {
  pagination: {
    type: Object,
  },
  cardsWrapperClass: {
    type: String,
  },
  label: {
    type: String,
  },
  query: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const { $t } = useGlobal()

const pluralForms = [
  $t('статья'),
  $t('статьи'),
  $t('статей'),
]

const __returned__ = { props, $t, pluralForms, get newsSearchCatalogResource() { return newsSearchCatalogResource }, get newsSearchCatalogCategoryResource() { return newsSearchCatalogCategoryResource }, get useGlobal() { return useGlobal } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})