import { App } from 'vue'

import ModalWrapper from './ModalWrapper.vue'

const register = (app: App<Element>): void => {
  app
    .component('promo-modal-wrapper', ModalWrapper)
}

export default {
  register,
}
