export function defaultDataTransformer(response: Response): any {
  return response.json().then((result: any) => result)
}

export function simpleTransformer(response: Response): Response {
  return response
}

export function fetcher(resource: string, init = {}): Promise<Response> {
  return fetch(resource, init)
}

export function transformDataFetcher(f: () => void, transformer: () => void): any {
  return function fn() {
    /* eslint-disable prefer-rest-params */
    return f.apply(this, arguments).then(transformer)
  }
}

export function jsonTransformer(response: Response): any {
  return response.json()
}

export function raiseHttpErrorTransformer(response: Response): any {
  if (!response.ok) {
    throw response
  }
  return response
}