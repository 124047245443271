import { defineComponent as _defineComponent } from 'vue'
import { ref, watch } from 'vue'
import { debounce } from 'lodash'


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterSorting',
  props: {
  value: {},
  resetTrigger: Boolean,
  defaultSorting: String,
  choices: {
    type: Array,
    default: () => ([]),
  },
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit

const values = ref(props.choices)

const defaultOption = values.value.find(el => el.label === props.defaultSorting)

const model = ref(null)

const update = (value: any) => {
  emit('update:modelValue', { sort: value.label })
}

const normalizeFrom = (val: any): string => {
  if (Array.isArray(val) && val.length) {
    return val[0]
  }

  return val
}

const setValueFromUrl = (val: any) => {
  if (!val) {
    model.value = defaultOption

    return
  }

  const normalizedValue = normalizeFrom(val)

  const checked = values.value.find(el => el.label === normalizedValue)

  if (!checked) return

  model.value = checked
}

const debounceUpdate = debounce(update, 100)
const debounceSetValue = debounce(setValueFromUrl, 200)

watch(() => props.value, () => {
  debounceSetValue(props.value)
}, { immediate: true })

// watch(() => props.resetTrigger, () => model.value = null)

const __returned__ = { props, emit, values, defaultOption, model, update, normalizeFrom, setValueFromUrl, debounceUpdate, debounceSetValue, ref, watch, get debounce() { return debounce } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})