import { App } from 'vue'

import FaqSearch from './FaqSearch.vue'

const register = (app: App<Element>): void => {
  app
    .component('faq-search', FaqSearch)
}

export default {
  register,
}
