import {
  createResource,
  postResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'order'

const ORDER_CREATE = prefixAPI('/create/', MODEL_PREFIX)
const ORDER_CHECK_VAT_NUMBER_CREATE = prefixAPI('/check-vat-number/', MODEL_PREFIX)
const ORDER_CALCULATE = prefixAPI('/calculate/', MODEL_PREFIX)

export const orderCreateResource = createResource(ORDER_CREATE, postResource)
export const orderCheckVatNumberResource = createResource(ORDER_CHECK_VAT_NUMBER_CREATE, postResource)
export const orderCalculateResource = createResource(ORDER_CALCULATE, postResource)
