import { App } from 'vue'

import BlogCatalog from './BlogCatalog.vue'
import BlogCatalogSearch from './BlogCatalogSearch.vue'

const register = (app: App<Element>): void => {
  app
    .component('blog-catalog', BlogCatalog)
    .component('blog-catalog-search', BlogCatalogSearch)
}

export default {
  register,
}
