import { App } from 'vue'

import ProjectCatalog from './ProjectCatalog.vue'
import ProjectCatalogSearch from './ProjectCatalogSearch.vue'
import ProjectGallery from './ProjectGallery.vue'

const register = (app: App<Element>): void => {
  app
    .component('project-catalog', ProjectCatalog)
    .component('project-catalog-search', ProjectCatalogSearch)
    .component('project-gallery', ProjectGallery)
}

export default {
  register,
}
