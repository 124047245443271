import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced g-row--space_sm" }
const _hoisted_2 = { class: "share-item__icon" }
const _hoisted_3 = { class: "share-item__text" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "share-item__icon" }
const _hoisted_6 = { class: "share-item__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_share_network = _resolveComponent("share-network")!
  const _component_icon_copy = _resolveComponent("icon-copy")!
  const _component_ui_copy_button = _resolveComponent("ui-copy-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.networksForShare, (item) => {
      return _createElementVNode("div", {
        class: _normalizeClass(["g-cell g-cols", { 'g-cols--6-xs g-cols--4-md': $setup.props.isModal }]),
        key: item.network
      }, [
        _createElementVNode("div", {
          class: "share-item",
          style: _normalizeStyle('--network-color:'+item.color)
        }, [
          _createVNode(_component_share_network, {
            class: "share-item__link",
            network: item.network,
            url: $setup.props.url,
            title: $setup.props.title
          }, null, 8, ["network", "url", "title"]),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon)))
          ]),
          _createElementVNode("span", _hoisted_3, _toDisplayString(item.title), 1)
        ], 4)
      ], 2)
    }), 64)),
    _createElementVNode("div", {
      class: _normalizeClass(["g-cell g-cols", { 'g-cols--6-xs g-cols--4-md': $setup.props.isModal }])
    }, [
      _createVNode(_component_ui_copy_button, {
        text: $setup.props.url
      }, {
        default: _withCtx(({ doCopy }) => [
          _createElementVNode("div", {
            class: "share-item",
            onClick: _withModifiers(doCopy, ["prevent"])
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_icon_copy)
            ]),
            _createElementVNode("span", _hoisted_6, _toDisplayString($setup.$t("Копировать")), 1)
          ], 8, _hoisted_4)
        ]),
        _: 1
      }, 8, ["text"])
    ], 2)
  ]))
}