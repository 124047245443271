import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_component_swiper, {
    modules: $setup.modules,
    autoHeight: false,
    spaceBetween: 30,
    speed: 500,
    breakpoints: $setup.breakpoints,
    slidesPerView: 2,
    pagination: { clickable: true, el: $props.paginationEl },
    navigation: { nextEl: $props.nextEl, prevEl: $props.prevEl }
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["pagination", "navigation"]))
}