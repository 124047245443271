import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced g-row--space_md g-row--space_2xl-xl" }
const _hoisted_2 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm" }
const _hoisted_3 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm" }
const _hoisted_4 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm" }
const _hoisted_5 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_input = _resolveComponent("control-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_control_input, {
        name: "firstName",
        type: "text",
        rules: "required|max:45",
        errors: $props.errors,
        "input-label": _ctx.$t("Имя"),
        modelValue: $props.formdata.firstName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($props.formdata.firstName) = $event))
      }, null, 8, ["errors", "input-label", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_control_input, {
        name: "lastName",
        type: "text",
        rules: "required|max:45",
        errors: $props.errors,
        "input-label": _ctx.$t("Фамилия"),
        modelValue: $props.formdata.lastName,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($props.formdata.lastName) = $event))
      }, null, 8, ["errors", "input-label", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      ($props.choices.phoneCodes.length)
        ? (_openBlock(), _createBlock(_component_control_input, {
            key: 0,
            class: "control-input--variant_phone",
            component: "ControlPhone",
            name: "phone",
            "max-height": 190,
            errors: $props.errors,
            "input-label": _ctx.$t("Номер телефону"),
            codes: $props.choices.phoneCodes,
            "predefined-code": $props.predefinedPhoneCode,
            rules: "required|maskedPhone:8|max:30",
            modelValue: $props.formdata.phone,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($props.formdata.phone) = $event)),
            "onCode:changed": _cache[3] || (_cache[3] = ($event: any) => (_ctx.emit('code:changed')))
          }, null, 8, ["errors", "input-label", "codes", "predefined-code", "modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_control_input, {
        name: "email",
        errors: $props.errors,
        "input-label": _ctx.$t("Эл. почта"),
        type: "email",
        rules: "required|email",
        modelValue: $props.formdata.email,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($props.formdata.email) = $event))
      }, null, 8, ["errors", "input-label", "modelValue"])
    ])
  ]))
}