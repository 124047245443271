import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "control-descriptor__icon control-descriptor__icon--prepend"
}
const _hoisted_2 = { class: "error-label" }
const _hoisted_3 = {
  key: 1,
  class: "control-descriptor__icon control-descriptor__icon--append"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VeeField = _resolveComponent("VeeField")!
  const _component_VeeError = _resolveComponent("VeeError")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["control-descriptor", { error: $props.errors[$props.name] }])
  }, [
    ($props.inputLabel)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent($props.labelComponent), _mergeProps({
          key: 0,
          "input-label": $props.inputLabel,
          "is-required": $setup.isRequired,
          value: $props.modelValue
        }, _ctx.$attrs), null, 16, ["input-label", "is-required", "value"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["control-descriptor__wrapper", { 'control-descriptor__wrapper--prepend': _ctx.$slots.prependIcon, 'control-descriptor__wrapper--append': _ctx.$slots.appendIcon }])
    }, [
      (_ctx.$slots.prependIcon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "prependIcon")
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_VeeField, _mergeProps({
        class: { error: $props.errors[$props.name] },
        as: $props.component,
        name: $props.name,
        options: $props.options,
        type: $props.type,
        "option-label": $props.optionLabel,
        "track-by": $props.trackBy,
        rules: $props.rules
      }, _ctx.$attrs, {
        modelValue: $props.modelValue,
        "onUpdate:modelValue": $setup.updateValue
      }), null, 16, ["class", "as", "name", "options", "type", "option-label", "track-by", "rules", "modelValue"]),
      _createVNode(_component_VeeError, { name: $props.name }, {
        default: _withCtx(({ message }) => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(message), 1)
        ]),
        _: 1
      }, 8, ["name"]),
      (_ctx.$slots.appendIcon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "appendIcon")
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}