import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_view = _resolveComponent("list-view")!

  return (_openBlock(), _createBlock(_component_list_view, {
    "url-path": $setup.urlPath,
    "clear-url-path": $setup.clearUrlPath,
    pagination: $props.pagination,
    "list-resource": $setup.collectionCatalogResource,
    "filters-resource": $setup.collectionCatalogFiltersResource,
    "cards-wrapper-class": $props.cardsWrapperClass,
    label: $props.label,
    categories: $props.categories,
    "plural-forms": $setup.pluralForms,
    "sort-values": $setup.sortValues,
    "categories-label": $setup.categoriesLabel,
    "prevent-title-change": true,
    "dynamic-filters": true
  }, {
    title: _withCtx(() => [
      _renderSlot(_ctx.$slots, "title")
    ]),
    additional: _withCtx(() => [
      _renderSlot(_ctx.$slots, "additional")
    ]),
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "content")
    ]),
    _: 3
  }, 8, ["url-path", "clear-url-path", "pagination", "list-resource", "filters-resource", "cards-wrapper-class", "label", "categories", "categories-label"]))
}