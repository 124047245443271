import { defineComponent as _defineComponent } from 'vue'
import {
  projectsSearchCatalogResource,
  projectsSearchCatalogCategoryResource,
} from '@/services/search.service'
import useGlobal from '@composables/useGlobal'


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectCatalogSearch',
  props: {
  pagination: {
    type: Object,
  },
  cardsWrapperClass: {
    type: String,
  },
  label: {
    type: String,
  },
  query: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const { $t } = useGlobal()

const pluralForms = [
  $t('проект'),
  $t('проекта'),
  $t('проектов'),
]

const __returned__ = { props, $t, pluralForms, get projectsSearchCatalogResource() { return projectsSearchCatalogResource }, get projectsSearchCatalogCategoryResource() { return projectsSearchCatalogCategoryResource }, get useGlobal() { return useGlobal } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})