import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, useSlots } from 'vue'
import { $vfm } from 'vue-final-modal'
import { cartChangeResource } from '@services/cart.service'
import { useShowModal } from '@composables/useModalOpener'
import { useCartStore } from '@store/cart'
import { useSimpleErrorsGetter } from '@/components/Forms/FormMixin'
import useGlobal from '@composables/useGlobal'


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonAdd',
  props: {
  itemId: {
    type: [String, Number],
  },
  type: {
    type: String,
  },
},
  setup(__props) {

const props = __props

const slots = useSlots() as any

const { $t } = useGlobal()

const isLoad = ref(false)

const getCartData = async () => {
  const cartStore = useCartStore()

  await cartStore.getCartData()
}

const openCartModal = () => {
  $vfm.show({
    component: 'UiModalContainer',
  }, {
    classes: ['modal--size_xl'],
    component: 'Modals/Cart/CartView',
  })
}

const addToCart = async (quantity: number) => {
  if (isLoad.value) return

  isLoad.value = true

  const formdata = {
    entities: [{
      quantity: quantity || 1,
      element: {
        type: props.type,
        id: props.itemId?.toString(),
      }
    }]
  }
  cartChangeResource.execute({}, formdata).then(async () => {
    await getCartData()

    openCartModal()
  }).catch(async (e: object) => {
    const parsed = await useSimpleErrorsGetter(e)

    const { entities, nonFieldErrors } = parsed

    const text = $t('Обратитесь к администратору сайта для получения подробной информации')

    if (nonFieldErrors && nonFieldErrors[0]) {
      const title = nonFieldErrors[0].message

      const message = {
        title,
        text,
      }
      useShowModal(message)
    } else if (entities && entities[0] && (entities[0].nonFieldErrors || entities[0].quantity)) {
      if (entities[0].nonFieldErrors) {
        const title = entities[0].nonFieldErrors[0].message

        const message = {
          title,
          text,
        }
        useShowModal(message)
      } else {
        const title = entities[0].quantity[0].message

        const message = {
          title,
          text,
        }
        useShowModal(message)
      }
    } else {
      const message = {
        title: $t('Возникла непредвиденная ошибка'),
        text,
      }
      useShowModal(message)
    }
  }).finally(() => {
    isLoad.value = false
  })
}

const render = () => slots.default({
  addToCart,
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(render, { ref: "content" }, null, 512))
}
}

})