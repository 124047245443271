import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced g-row--space_md g-row--space_2xl-xl" }
const _hoisted_2 = { class: "g-cell g-cols g-cols--12-xs g-cols--6-sm g-cols--4-lg g-cols--6-xl g-cols--4-hd" }
const _hoisted_3 = { class: "g-cell g-cols g-cols--12-xs g-cols--6-sm g-cols--4-lg g-cols--6-xl g-cols--8-hd" }
const _hoisted_4 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm g-cols--4-lg g-cols--6-xl g-cols--4-hd" }
const _hoisted_5 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm g-cols--8-lg g-cols--6-xl g-cols--8-hd" }
const _hoisted_6 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm g-cols--4-lg g-cols--6-xl g-cols--4-hd" }
const _hoisted_7 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm g-cols--4-lg g-cols--6-xl g-cols--4-hd" }
const _hoisted_8 = { class: "error-label" }
const _hoisted_9 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm g-cols--4-lg g-cols--6-xl g-cols--4-hd" }
const _hoisted_10 = { class: "error-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_select = _resolveComponent("control-select")!
  const _component_control_input = _resolveComponent("control-input")!
  const _component_VeeError = _resolveComponent("VeeError")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_control_select, {
        modelValue: $props.formdata.memberState,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($props.formdata.memberState) = $event)),
        "option-label": "label",
        name: "memberState",
        rules: "required",
        "allow-empty": true,
        searchable: true,
        options: $props.choices.memberStates,
        errors: $props.errors,
        "input-label": _ctx.$t("Member State")
      }, null, 8, ["modelValue", "options", "errors", "input-label"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_control_input, {
        name: "regNumber",
        type: "text",
        rules: "required|max:45",
        errors: $props.errors,
        "input-label": _ctx.$t("Регистрационный номер компании"),
        modelValue: $props.formdata.regNumber,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($props.formdata.regNumber) = $event))
      }, null, 8, ["errors", "input-label", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_control_input, {
        name: "companyName",
        type: "text",
        rules: "required|max:255",
        errors: $props.errors,
        "input-label": _ctx.$t("Наименование"),
        modelValue: $props.formdata.companyName,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($props.formdata.companyName) = $event))
      }, null, 8, ["errors", "input-label", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_control_input, {
        name: "legalAddress",
        type: "text",
        rules: "required|max:255",
        errors: $props.errors,
        "input-label": _ctx.$t("Адрес"),
        modelValue: $props.formdata.legalAddress,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($props.formdata.legalAddress) = $event))
      }, null, 8, ["errors", "input-label", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_control_input, {
        name: "vatNumber",
        type: "text",
        rules: "max:45",
        errors: $props.errors,
        "input-label": _ctx.$t("НДС номер"),
        modelValue: $props.formdata.vatNumber,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($props.formdata.vatNumber) = $event))
      }, null, 8, ["errors", "input-label", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      ($props.choices.phoneCodes.length)
        ? (_openBlock(), _createBlock(_component_control_input, {
            key: 0,
            class: "control-input--variant_phone",
            component: "ControlPhone",
            name: "phoneLegal",
            "max-height": 190,
            errors: $props.errors,
            "input-label": _ctx.$t("Номер телефону"),
            codes: $props.choices.phoneCodes,
            "predefined-code": $props.predefinedPhoneCode,
            rules: "required|maskedPhone:8|max:30",
            modelValue: $props.formdata.phone,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($props.formdata.phone) = $event)),
            "onCode:changed": _cache[6] || (_cache[6] = ($event: any) => ($setup.emit('code:changed')))
          }, null, 8, ["errors", "input-label", "codes", "predefined-code", "modelValue"]))
        : _createCommentVNode("", true),
      _createVNode(_component_VeeError, { name: "phone" }, {
        default: _withCtx(({ message }) => [
          _createElementVNode("span", _hoisted_8, _toDisplayString(message), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_control_input, {
        name: "emailLegal",
        errors: $props.errors,
        "input-label": _ctx.$t("Эл. почта"),
        type: "email",
        rules: "required|email",
        modelValue: $props.formdata.email,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($props.formdata.email) = $event))
      }, null, 8, ["errors", "input-label", "modelValue"]),
      _createVNode(_component_VeeError, { name: "email" }, {
        default: _withCtx(({ message }) => [
          _createElementVNode("span", _hoisted_10, _toDisplayString(message), 1)
        ]),
        _: 1
      })
    ])
  ]))
}