import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "checkout" }
const _hoisted_2 = { class: "checkout__main" }
const _hoisted_3 = { class: "ds-panel ds-panel--space_3xl ds-panel--space_6xl-xl" }
const _hoisted_4 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_5 = { class: "ds-panel ds-panel--space_3xl ds-panel--space_6xl-xl" }
const _hoisted_6 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_7 = { class: "ds-caption ds-caption--size_md ds-caption--weight_bold ds-caption--appearance_uppercase" }
const _hoisted_8 = { class: "g-row g-row--appearance_spaced g-row--space_md g-row--space_2xl-xl" }
const _hoisted_9 = { class: "g-cell g-cols g-cols--12-xs" }
const _hoisted_10 = { class: "g-row g-row--space_md g-row--space_2xl-xl" }
const _hoisted_11 = { class: "g-cell g-cols g-cols--12-xs g-cols--6-sm g-cols--4-lg g-cols--6-xl g-cols--4-hd" }
const _hoisted_12 = {
  key: 0,
  class: "g-cell g-cols g-cols--12-xs"
}
const _hoisted_13 = { class: "g-row" }
const _hoisted_14 = { class: "g-cell g-cols g-cols--12-xs g-cols--8-lg g-cols--12-xl g-cols--8-hd" }
const _hoisted_15 = {
  key: 0,
  class: "g-cell g-cols g-cols--12-xs"
}
const _hoisted_16 = { class: "ds-panel ds-panel--space_3xl ds-panel--space_6xl-xl" }
const _hoisted_17 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_18 = { class: "ds-panel ds-panel--space_3xl ds-panel--space_6xl-xl" }
const _hoisted_19 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_20 = { class: "ds-caption ds-caption--size_md ds-caption--weight_bold ds-caption--appearance_uppercase" }
const _hoisted_21 = { class: "ds-panel ds-panel--space_3xl ds-panel--space_6xl-xl" }
const _hoisted_22 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_23 = { class: "ds-caption ds-caption--size_md ds-caption--weight_bold ds-caption--appearance_uppercase" }
const _hoisted_24 = { class: "checkout__aside" }
const _hoisted_25 = { class: "ds-panel ds-panel--space_md" }
const _hoisted_26 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_27 = { class: "error-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_select = _resolveComponent("control-select")!
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createBlock(_component_VeeForm, {
    class: "relative",
    onSubmit: $setup.validateBeforeSubmit,
    onInvalidSubmit: $setup.showOrderErrorModal
  }, {
    default: _withCtx(({ errors, setErrors }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["i-preloader", { 'is-active': $setup.isLoad }])
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("div", { class: "i-preloader__item" }, null, -1)
      ]), 2),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("p", _hoisted_7, _toDisplayString($setup.$t("Контактная информация")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_control_select, {
                        modelValue: $setup.formdata.clientType,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formdata.clientType) = $event)),
                        "option-label": "title",
                        name: "clientType",
                        rules: "required",
                        "allow-empty": false,
                        options: $setup.choices.clientType,
                        errors: errors,
                        "input-label": $setup.$t("Вид лица")
                      }, null, 8, ["modelValue", "options", "errors", "input-label"])
                    ])
                  ])
                ]),
                (_openBlock(), _createBlock(_KeepAlive, null, [
                  ($setup.isIndividualClient)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("div", _hoisted_14, [
                            _createVNode($setup["CheckoutIndividualEntity"], {
                              formdata: $setup.formdata.individual,
                              choices: $setup.choices,
                              errors: errors,
                              "predefined-phone-code": $setup.predefinedPhoneCode,
                              "onCode:changed": $setup.setCountryCode
                            }, null, 8, ["formdata", "choices", "errors", "predefined-phone-code"])
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 1024)),
                (_openBlock(), _createBlock(_KeepAlive, null, [
                  ($setup.isLegalClient)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                        _createVNode($setup["CheckoutLegalEntity"], {
                          formdata: $setup.formdata.legal,
                          choices: $setup.choices,
                          errors: errors,
                          "predefined-phone-code": $setup.predefinedPhoneCode,
                          "onCode:changed": $setup.setCountryCode
                        }, null, 8, ["formdata", "choices", "errors", "predefined-phone-code"])
                      ]))
                    : _createCommentVNode("", true)
                ], 1024))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("p", _hoisted_20, _toDisplayString($setup.$t("Доставка")), 1)
                ])
              ]),
              _createVNode($setup["CheckoutDelivery"], {
                "current-country": $setup.currentCountry,
                formdata: $setup.formdata,
                errors: errors,
                choices: $setup.choices,
                "onPostalcode:changed": ($event: any) => ($setup.postalCodeChanged(setErrors)),
                "onDelivery:way:changed": ($event: any) => ($setup.calculateDeliveryDebounce(setErrors))
              }, null, 8, ["current-country", "formdata", "errors", "choices", "onPostalcode:changed", "onDelivery:way:changed"])
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("p", _hoisted_23, _toDisplayString($setup.$t("Способ оплаты")), 1)
            ])
          ]),
          _createVNode($setup["CheckoutPayment"], { choices: $setup.choices }, null, 8, ["choices"])
        ]),
        _createElementVNode("div", _hoisted_24, [
          _createVNode($setup["CheckoutSummary"], {
            formdata: $setup.formdata,
            cart: $setup.localCart,
            "price-info": $setup.priceInfo,
            "is-load": $setup.isLoad
          }, null, 8, ["formdata", "cart", "price-info", "is-load"]),
          _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
            default: _withCtx(({ message }) => [
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("span", _hoisted_27, _toDisplayString(message), 1)
                ])
              ])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}