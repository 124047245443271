import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue'
import { CURRENT_LANGUAGE, prefixLanguage } from '@utils/urls'
import useChoices from '@/choices'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiLanguageSwitch',
  setup(__props, { expose: __expose }) {
  __expose();

const { languages } = useChoices()

const currentItem = computed(() => {
  return languages.find(el => el.value === CURRENT_LANGUAGE)
})

const filteredItems = computed(() => {
  return languages.filter(el => el.value !== CURRENT_LANGUAGE)
})

const getLanguageLink = (language: string) => prefixLanguage(window.location.href, { language })

const __returned__ = { languages, currentItem, filteredItems, getLanguageLink, computed, get CURRENT_LANGUAGE() { return CURRENT_LANGUAGE }, get prefixLanguage() { return prefixLanguage }, get useChoices() { return useChoices } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})