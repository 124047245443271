import { defineComponent as _defineComponent } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'ProductCardCompare',
  props: {
  item: {
    type: Object,
  },
},
  emits: ['compare:changed'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit

const placeholderImage = window.placeholderImage

const __returned__ = { props, emit, placeholderImage }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})