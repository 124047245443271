import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ui-dropdown" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "ui-dropdown__single"
}
const _hoisted_4 = { class: "i-info" }
const _hoisted_5 = { class: "i-info__icon" }
const _hoisted_6 = { class: "i-info__content" }
const _hoisted_7 = { class: "ds-caption ds-caption--color_white ds-caption--size_sm" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "ui-dropdown__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_global = _resolveComponent("icon-global")!
  const _component_icon_chevron_down = _resolveComponent("icon-chevron-down")!
  const _component_ui_click_outside = _resolveComponent("ui-click-outside")!
  const _component_ui_dropdown = _resolveComponent("ui-dropdown")!

  return (_openBlock(), _createBlock(_component_ui_dropdown, null, {
    default: _withCtx(({ isActive, toggle, hide }) => [
      _createVNode(_component_ui_click_outside, { onTrigger: hide }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "ui-dropdown__element",
              onClick: _withModifiers(toggle, ["prevent"])
            }, [
              ($setup.currentItem)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_icon_global)
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("span", _hoisted_7, _toDisplayString($setup.currentItem.title), 1)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("span", {
                class: _normalizeClass(["ui-dropdown__icon", { 'is-active': isActive }])
              }, [
                _createVNode(_component_icon_chevron_down)
              ], 2)
            ], 8, _hoisted_2),
            _createElementVNode("div", {
              class: _normalizeClass(["ui-dropdown__wrapper", { 'is-active': isActive }])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredItems, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "ui-dropdown__item",
                  onClick: _withModifiers(($event: any) => ($setup.changeCountry(item.isoCode)), ["prevent"])
                }, [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(item.title), 1)
                ], 8, _hoisted_8))
              }), 256))
            ], 2)
          ])
        ]),
        _: 2
      }, 1032, ["onTrigger"])
    ]),
    _: 1
  }))
}