<template>
  <render />
</template>

<script setup lang="ts">
import { useSlots, useAttrs } from 'vue'
import { getVideoId, getVideoPreview } from '@utils/video.js'
import { $vfm } from 'vue-final-modal'

interface Props {
  component?: string,
  info?: object,
  videoUrl?: string,
  classes?: Array<string>,
  clickToClose?: boolean,
}

const props = defineProps<Props>()

const slots = useSlots()
const attrs = useAttrs()

const videoPreview = () => {
  if (props.videoUrl) {
    const videoId = getVideoId(props.videoUrl)
    const preview = getVideoPreview(videoId)

    return preview
  }
}

const openModal = () => {
  $vfm.show({
    component: 'UiModalContainer',
  }, {
    ...props,
    ...attrs,
  })
}

const render = () => slots.default({
  open: openModal,
  videoPreview: videoPreview(),
})
</script>
