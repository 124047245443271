import { defineComponent as _defineComponent } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutIndividualEntity',
  props: {
  formdata: {
    type: Object,
  },
  choices: {
    type: Object,
  },
  predefinedPhoneCode: {
    type: Object,
  },
  errors: {
    type: Object,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const __returned__ = { props }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})