import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ui-uploader__main" }
const _hoisted_2 = { class: "ui-uploader__content" }
const _hoisted_3 = ["id", "name", "accept", "multiple"]
const _hoisted_4 = {
  key: 0,
  class: "ui-uploader__list"
}
const _hoisted_5 = { class: "g-row g-row--space_sm g-row--appearance_spaced" }
const _hoisted_6 = { class: "i-file__content" }
const _hoisted_7 = { class: "i-file__title" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "ui-uploader__errors" }
const _hoisted_10 = {
  key: 0,
  class: "error-label"
}
const _hoisted_11 = {
  key: 1,
  class: "error-label"
}
const _hoisted_12 = {
  key: 2,
  class: "error-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_close = _resolveComponent("icon-close")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ui-uploader", { 'ui-uploader--variant_multiple': $props.multiple }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["ui-uploader__wrapper", {'is-disabled': $setup.notDeletedFiles.length >= $setup.props.maxFiles}])
        }, [
          _createElementVNode("label", null, [
            _renderSlot(_ctx.$slots, "default"),
            _createElementVNode("input", {
              ref: "fileField",
              style: {"display":"none"},
              type: "file",
              id: $props.inputId,
              name: $props.inputId,
              accept: $setup.acceptedFilesLocal,
              multiple: $props.multiple,
              onChange: $setup.onFileChange
            }, null, 40, _hoisted_3)
          ])
        ], 2)
      ]),
      ($setup.notDeletedFiles.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.notDeletedFiles, (file, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "g-cell g-cols g-cols--12-xs",
                  key: index
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["i-file", { 'is-interactive': !file.isUploaded }])
                  }, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(file.name), 1)
                    ]),
                    (!$props.disabled)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "i-file__close",
                          onClick: _withModifiers(($event: any) => ($setup.removeFile(index)), ["prevent"])
                        }, [
                          _createVNode(_component_icon_close)
                        ], 8, _hoisted_8))
                      : _createCommentVNode("", true)
                  ], 2)
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_9, [
      ($setup.fileErorrs.limit)
        ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString($setup.$t("Превышено максимальное количество файлов для загрузки")), 1))
        : _createCommentVNode("", true),
      ($setup.fileErorrs.size)
        ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString($setup.$t("Превышенный максимальный вес файла для загрузки")), 1))
        : _createCommentVNode("", true),
      ($setup.fileErorrs.type)
        ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString($setup.$t("Не поддерживаемый формат файла")), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}