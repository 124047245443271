import { defineComponent as _defineComponent } from 'vue'
import { ref, watch } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ControlSwitch',
  props: {
  modelValue: {},
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit

const model = ref(false)

watch(() => props.modelValue, () => {
  model.value = Boolean(props.modelValue)
}, { immediate: true })

const updateValue = () => {
  emit('update:modelValue', model.value)
}

const __returned__ = { props, emit, model, updateValue, ref, watch }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})