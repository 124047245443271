import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "control-label__required"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["control-label control-label--variant_bound", { 'is-active': !$setup.isEmpty($props.value) }]),
    for: $setup.props.id
  }, [
    _createTextVNode(_toDisplayString($setup.props.inputLabel), 1),
    ($setup.props.isRequired)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}