import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced" }
const _hoisted_2 = { class: "g-cell g-cols g-cols--12-xs" }
const _hoisted_3 = { class: "card card--variant_2" }
const _hoisted_4 = { class: "ds-caption ds-caption--size_md ds-caption--weight_medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.choices.payments, (item) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(item.title), 1)
        ])
      ]))
    }), 256))
  ]))
}