import { defineComponent as _defineComponent } from 'vue'
import { ref, provide, onMounted } from 'vue'
import { useChallengeV2 } from 'vue-recaptcha'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiRecaptcha',
  setup(__props, { expose: __expose }) {
  __expose();

const { root, execute, onVerify, reset } = useChallengeV2({
  options: {
    size: 'invisible',
    badge: 'bottomleft',
  }
})

const token = ref('')

const initIframe = () => {
  let isLoaded = false

  const int = setInterval(async () => {
    const iframes = document.querySelectorAll<HTMLElement>('iframe')

    iframes.forEach(item => {
      if (item.title.includes('recaptcha challenge')) {
        item.classList.add('recaptcha-challenge')

        if (item.parentElement) {
          item.parentElement.classList.add('recaptcha-challenge-parent')
        }

        isLoaded = true
      }
    })

    if (isLoaded) {
      clearInterval(int)
    }
  }, 1000)
}

const resetCaptcha = () => {
  reset()
  initIframe()
}

onMounted(() => {
  initIframe()
})

onVerify((response: string) => {
  token.value = response
})

provide('captchaConfig', {
  token,
  execute,
  initIframe,
  resetCaptcha,
})

const __returned__ = { root, execute, onVerify, reset, token, initIframe, resetCaptcha, ref, provide, onMounted, get useChallengeV2() { return useChallengeV2 } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})