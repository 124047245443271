import { defineComponent as _defineComponent } from 'vue'

import { getMimeType } from '@utils/files'
import { $vfm } from 'vue-final-modal'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiCropper',
  emits: ['image:crop'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit

const loadImage = event => {
  const uploadedImage = { src: null, type: null, name: null }

  const { files } = event.target

  if (!files || (files && !files.length)) return

  if (uploadedImage.src) {
    URL.revokeObjectURL(uploadedImage.src)
  }

  const [file] = files
  const blob = URL.createObjectURL(file)
  const reader = new FileReader()

  reader.onload = e => {
    uploadedImage.src = blob
    uploadedImage.type = getMimeType(e.target.result, file.type)
    uploadedImage.name = file.name

    openCropperModal(uploadedImage)

    event.target.value = null
  }

  reader.readAsArrayBuffer(file)
}

const emitImage = image => {
  emit('image:crop', image)
}

const openCropperModal = image => {
  $vfm.show({
    component: 'UiModalContainer',
  }, {
    classes: 'modal--size_xl',
    component: 'Modals/Common/ModalCropper',
    info: {
      callback: emitImage,
      image,
    },
  })
}

const __returned__ = { emit, loadImage, emitImage, openCropperModal, get getMimeType() { return getMimeType }, get $vfm() { return $vfm } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})