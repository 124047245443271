import { defineComponent as _defineComponent } from 'vue'
import { reactive, computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductOrder',
  props: {
  productId: {
    type: [String, Number],
  },
  productType: {
    type: String,
  },
  maxQuantity: {
    type: Number,
  },
  minQuantity: {
    type: Number,
  },
  needConvertArea: {
    type: Boolean,
  },
  inStock: {
    type: Boolean,
  },
  quantityInBox: {
    type: Number,
  },
  price: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const maxQuantityInBox = props.quantityInBox * props.maxQuantity

const formdata = reactive({
  quantity: props.minQuantity || 1,
  quantityInBox: props.quantityInBox,
})

const quantityInBoxChangeHandler = () => {
  formdata.quantity = Math.round(Number(formdata.quantityInBox) / props.quantityInBox)
}

const quantityChangeHandler = () => {
  if (!props.needConvertArea) return

  formdata.quantityInBox = Number((Number(formdata.quantity) * props.quantityInBox).toFixed(2))
}

const totalPrice = computed(() => {
  if (!props.needConvertArea) return 0

  const price = props.price.replace(',', '.')

  return Number(price) * Number(formdata.quantityInBox)
})

const __returned__ = { props, maxQuantityInBox, formdata, quantityInBoxChangeHandler, quantityChangeHandler, totalPrice, reactive, computed }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})