import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sorting-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_select = _resolveComponent("control-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_control_select, {
      class: "multiselect--variant_1",
      modelValue: $setup.model,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event)),
        $setup.debounceUpdate
      ],
      "option-label": "title",
      name: "sort",
      "input-label": "",
      multiple: false,
      searchable: false,
      "allow-empty": false,
      options: $setup.values
    }, null, 8, ["modelValue", "options", "onUpdate:modelValue"])
  ]))
}