import { App } from 'vue'
import Feedback from './FormFeedback.vue'
import FeedbackCommon from './FormFeedbackCommon.vue'

const register = (app: App<Element>): void => {
  app
    .component('form-feedback', Feedback)
    .component('form-feedback-common', FeedbackCommon)
}

export default {
  register,
}
