export const megabyte = 1048576

export const formatBytes = (bytes, decimals = 2) => {
  const k = 1000
  const dm = 0 > decimals ? 0 : decimals
  const sizes = ['bytes', 'kB', 'MB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

export const getFileSize = async url => {
  const res = await fetch(url)
  const fileBlob = await res.blob()

  return fileBlob.size
}

export const getFileName = url => {
  const lastIndexOf = url.lastIndexOf('/') + 1
  const name = url.substr(lastIndexOf)

  return name
}

export function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4)
  let header = ''

  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16)
  }

  switch (header) {
  case '89504e47':
    return 'image/png'
  case '47494638':
    return 'image/gif'
  case 'ffd8ffe0':
  case 'ffd8ffe1':
  case 'ffd8ffe2':
  case 'ffd8ffe3':
  case 'ffd8ffe8':
    return 'image/jpeg'
  default:
    return fallback
  }
}

export const toDataURL = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))

export function resizeImage(datas, wantedWidth, wantedHeight) {
  return new Promise(function(resolve, reject){
    // We create an image to receive the Data URI
    const img = document.createElement('img')

    // When the event "onload" is triggered we can resize the image.
    img.onload = function() {
      // We create a canvas and get its context.
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      // We set the dimensions at the wanted size.
      canvas.width = wantedWidth
      canvas.height = wantedHeight

      // We resize the image with the canvas method drawImage()
      ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight)

      const dataURI = canvas.toDataURL()

      // This is the return of the Promise
      resolve(dataURI)
    }

    // We put the Data URI in the image's src attribute
    img.src = datas
  })
}