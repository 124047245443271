import { App } from 'vue'

import Validation from './validation/plugin'
import VueFinalModal from './vue-final-modal'
import VueMask from './vue-inputmask'
import VueSwiper from './vue-swiper'
import VueRecaptcha from './vue-recaptcha'
import VueObserveVisibility from './vue-observe-visibility'
import VueSocialSharing from './vue-social-sharing'
import VueClipboard from './vue-clipboard'
import VueNotification from './vue-notification'
import VueTooltip from './vue-tooltip'
import VueViewer from './vue-viewer'
import VueSliderComponent from './vue-slider-component'

export default {
  install(app: App<Element>): any {
    app
      .use(Validation)
      .use(VueFinalModal)
      .use(VueMask)
      .use(VueSwiper)
      .use(VueRecaptcha)
      .use(VueObserveVisibility)
      .use(VueSocialSharing)
      .use(VueClipboard)
      .use(VueNotification)
      .use(VueTooltip)
      .use(VueViewer)
      .use(VueSliderComponent)
  }
}
