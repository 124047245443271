import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ds-panel ds-panel--space_md ds-panel--space_2xl" }
const _hoisted_2 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_3 = { class: "g-row" }
const _hoisted_4 = { class: "g-cell g-cols g-cols--6-xs" }
const _hoisted_5 = { class: "g-cell g-cols g-cols--6-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_range_slider = _resolveComponent("vue-range-slider")!
  const _component_control_input = _resolveComponent("control-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_vue_range_slider, {
      modelValue: $setup.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event)),
      transition: "0.2",
      clickable: false,
      "drag-on-click": true,
      min: $props.filter.values.min,
      max: $props.filter.values.max,
      height: 3,
      interval: $setup.interval,
      onDragEnd: $setup.update
    }, null, 8, ["modelValue", "min", "max", "interval"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_control_input, {
              modelValue: $setup.model[0],
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.model[0]) = $event)),
              name: "min",
              type: "number",
              onInput: $setup.debounceUpdate
            }, null, 8, ["modelValue", "onInput"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_control_input, {
              modelValue: $setup.model[1],
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.model[1]) = $event)),
              name: "max",
              type: "number",
              onInput: $setup.debounceUpdate
            }, null, 8, ["modelValue", "onInput"])
          ])
        ])
      ])
    ])
  ]))
}