import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "control-button__element" }
const _hoisted_2 = { class: "control-button__icon control-button__icon--size_1" }
const _hoisted_3 = {
  key: 0,
  class: "i-preloader i-preloader--size_1 i-preloader--bg_none is-active"
}
const _hoisted_4 = { class: "control-button__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_plus = _resolveComponent("icon-plus")!

  return (_openBlock(), _createElementBlock("a", {
    class: "control-button control-button--variant_transparent control-button--view_block",
    onClick: _withModifiers($setup.load, ["prevent"])
  }, [
    _createElementVNode("span", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, [
        ($props.isLoad)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _cache[0] || (_cache[0] = [
              _createElementVNode("span", { class: "i-preloader__item" }, null, -1)
            ])))
          : (_openBlock(), _createBlock(_component_icon_plus, { key: 1 }))
      ]),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("Загрузить еще")), 1)
    ])
  ]))
}