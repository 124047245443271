import { defineComponent as _defineComponent } from 'vue'
import { ref, computed } from 'vue'
import { Autoplay, Pagination, Thumbs } from 'swiper'
import { getVideoId, getVideoPreview } from '@utils/video'


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductGallery',
  props: {
  images: {
    type: Array,
  },
  videos: {
    type: Array,
  },
  needPagination: {
    type: Boolean,
    default: false,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const modules = [Autoplay, Pagination, Thumbs]

const thumbsSwiper = ref(null)

const placeholderImage = window.placeholderImage

const setThumbsSwiper = (swiper: any) => {
  thumbsSwiper.value = swiper
}

const generateVideoUrl = (videoId: string) => {
  return `//www.youtube.com/embed/${videoId}?autoplay=1`
}

const preparedImages = computed(() => {
  const allItems = [...props.images, ...props.videos]

  return allItems.map(item => {
    if (item.video_url) {
      const videoId = getVideoId(item.video_url)
      const videoUrl = generateVideoUrl(videoId)

      if (!item.image_url.base) {
        const preview = getVideoPreview(videoId)

        item.image_url.base = preview
        item.image_url_sized.base = preview
      }

      item.video_url = videoUrl
    }

    return item
  })
})

const breakpoints = {
  320: {
    slidesPerView: 4,
  },
  1440: {
    slidesPerView: 5,
  },
  1620: {
    slidesPerView: 6,
  },
}

const __returned__ = { props, modules, thumbsSwiper, placeholderImage, setThumbsSwiper, generateVideoUrl, preparedImages, breakpoints, ref, computed, get Autoplay() { return Autoplay }, get Pagination() { return Pagination }, get Thumbs() { return Thumbs }, get getVideoId() { return getVideoId }, get getVideoPreview() { return getVideoPreview } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})