import { defineComponent as _defineComponent } from 'vue'
import { Navigation, Grid } from 'swiper'


export default /*@__PURE__*/_defineComponent({
  __name: 'SliderNewsAside',
  setup(__props, { expose: __expose }) {
  __expose();

const modules = [Navigation, Grid]

const breakpoints = {
  0: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  564: {
    slidesPerView: 2,
  },
  992: {
    slidesPerView: 3,
  },
  1200: {
    slidesPerView: 1,
    autoHeight: false,
    grid: {
      rows: 4,
    },
  },
}

const __returned__ = { modules, breakpoints, get Navigation() { return Navigation }, get Grid() { return Grid } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})