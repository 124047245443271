import {
  createResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'grouped-search'

const SEARCH = prefixAPI('/{?search}', MODEL_PREFIX)

export const searchResource = createResource(SEARCH)
