import { App } from 'vue'

import ButtonDetails from './ButtonDetails.vue'
import ButtonAdd from './ButtonAdd.vue'
import ButtonRemove from './ButtonRemove.vue'

const register = (app: App<Element>): void => {
  app
    .component('cart-button-details', ButtonDetails)
    .component('cart-button-add', ButtonAdd)
    .component('cart-button-remove', ButtonRemove)
}

export default {
  register,
}
