import { defineComponent as _defineComponent } from 'vue'
import { ref, watch, nextTick } from 'vue'
import useGlobal from '@composables/useGlobal'


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckboxWidget',
  props: {
  filter: Object,
  resetTrigger: Boolean,
  value: {},
  dynamicFilters: {
    type: Boolean,
  },
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const { $t } = useGlobal()

const emit = __emit

const model = ref(props.value || [])

const placeholder = $t('Поиск')

const itemsFiltered = ref(props.filter.values)
const searchValue = ref('')

const update = () => {
  emit('update:modelValue', model.value)
}

const normalizeValue = (val: any) => {
  let value = val

  if (!Array.isArray(val)) {
    value = [val]
  }

  return value
}

const setValueFromUrl = (val: any) => {
  const items = props.filter.values
  const normalizedValue = normalizeValue(val)

  if (!normalizedValue) return

  const checked = items.filter(el => normalizedValue.find(v => v === el.label.toString()))

  if (!checked) return

  const p = checked.map(el => el.label)

  model.value = p
}

const searchItems = async () => {
  await nextTick()

  const items = JSON.parse(JSON.stringify(props.filter.values))

  itemsFiltered.value = items.filter(item => {
    const searchVal = searchValue.value.toLowerCase()
    const itemName = item.title.toLowerCase()

    return itemName.includes(searchVal)
  })
}

watch(() => props.value, (nval) => {
  setValueFromUrl(nval)
}, { immediate: true, deep: true })

watch(() => props.resetTrigger, () => model.value = [])

const __returned__ = { props, $t, emit, model, placeholder, itemsFiltered, searchValue, update, normalizeValue, setValueFromUrl, searchItems, ref, watch, nextTick, get useGlobal() { return useGlobal } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})