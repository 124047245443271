import { defineComponent as _defineComponent } from 'vue'
import { ref, watch, computed } from 'vue'
import { debounce } from 'lodash'

/* eslint-disable */

export default /*@__PURE__*/_defineComponent({
  __name: 'RangeWidget',
  props: {
  value: {},
  filter: Object,
  resetTrigger: Boolean,
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const gcd = (a, b, onZero = 1) => (
  a < b
    ? gcd(b, a, onZero)
    : (
      b < 0.001 || isNaN(b) || isNaN(b)
        ? (0 === a ? onZero : a)
        : gcd(b, a - Math.floor(a / b) * b)
    )
)

const props = __props

const emit = __emit

const model = ref([])

const interval = computed(() => {
  const { values } = props.filter
  const v = parseFloat(gcd(Math.abs(values.min), Math.abs(values.max)).toFixed(2))

  return v > 1 ? 1 : v
})

const update = () => {
  const { values } = props.filter

  if (Number(model.value[0]) === values.min && Number(model.value[1]) === values.max) {
    emit('update:modelValue', [])
  } else {
    emit('update:modelValue', model.value)
  }
}

const normalizeValue = (val: any) => {
  const { values } = props.filter

  if (!val) {
    return [values.min, values.max]
  }

  const [min, max] = val

  return [min || values.min, max || values.max]
}

const setValueFromUrl = (val: any) => {
  const normalizedValue = normalizeValue(val)

  model.value = normalizedValue
}

const debounceUpdate = debounce(update, 800)

watch(() => props.value, () => {
  setValueFromUrl(props.value)
}, { immediate: true })

watch(() => props.resetTrigger, () => {
  normalizeValue(null)
})

const __returned__ = { gcd, props, emit, model, interval, update, normalizeValue, setValueFromUrl, debounceUpdate, ref, watch, computed, get debounce() { return debounce } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})