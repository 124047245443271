import { defineComponent as _defineComponent } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'LoadMorePagination',
  props: {
  pagination: {
    type: Object,
  },
  isLoad: {
    type: Boolean,
  },
},
  emits: ['pagination:change'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit

const load = () => {
  const page = props.pagination.page + 1

  emit('pagination:change', page)
}

const __returned__ = { props, emit, load }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})