<template>
  <render />
</template>

<script setup lang="ts">
import { useSlots } from 'vue'
import { $vfm } from 'vue-final-modal'
import { useComparisonStore } from '@store/comparison'

const slots = useSlots()

const comparisonStore = useComparisonStore()

const openModal = () => {
  $vfm.show({
    component: 'UiModalContainer',
  }, {
    classes: [],
    component: 'Modals/Compare/CompareView',
  })
}

const render = () => slots.default({
  quantity: comparisonStore.comparisonInfo.count || 0,
  openModal,
  isDeterminated: comparisonStore.comparisonInfo.isDeterminated,
})
</script>
