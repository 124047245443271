import { App } from 'vue'
import BackLink from './BackLink.vue'

const register = (app: App<Element>): void => {
  app
    .component('profile-back-link', BackLink)
}

export default {
  register,
}
