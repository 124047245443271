import {
  baseResource,
  createResource,
} from '@/resource/resource'
import { prefixAPI } from '@/resource/api'
import { prefixLanguage } from '@/utils/urls'
import { friendlyUrlGenerator } from '@/utils/friendly'

const PRODUCT_CATALOG_FILTERS = prefixAPI('/filter-attributes', 'catalog')
const PRODUCT_CATALOG_OUTLET_FILTERS = prefixAPI('/filter-attributes', 'outlet')

const FILTERS_LIST_URL_PREFIX = prefixLanguage('/catalog')
const FILTERS_OUTLET_LIST_URL_PREFIX = prefixLanguage('/outlet')

export const productCatalogResource = friendlyUrlGenerator([
  FILTERS_LIST_URL_PREFIX, 'ajax'
], baseResource)

export const productCatalogOutletResource = friendlyUrlGenerator([
  FILTERS_OUTLET_LIST_URL_PREFIX, 'ajax'
], baseResource)

export const productCatalogFiltersResource = friendlyUrlGenerator([
  PRODUCT_CATALOG_FILTERS, 'filters'
], baseResource)

export const productCatalogOutletFiltersResource = friendlyUrlGenerator([
  PRODUCT_CATALOG_OUTLET_FILTERS, 'filters'
], baseResource)

