import { App } from 'vue'

import Ui from './Ui'
import Profile from './Profile'
import Controls from './Controls'
import Modals from './Modals'
import Cart from './Cart'
import Compare from './Compare'
import Favourite from './Favourite'
import Forms from './Forms'
import Sliders from './Sliders'
import Product from './Product'
import List from './List'
import Blog from './Blog'
import Project from './Project'
import Checkout from './Checkout'
import Collection from './Collection'
import Faq from './Faq'
import Promo from './Promo'

const install = (app: App<Element>): void => {
  Ui.register(app)
  Profile.register(app)
  Controls.register(app)
  Modals.register(app)
  Cart.register(app)
  Compare.register(app)
  Favourite.register(app)
  Forms.register(app)
  Sliders.register(app)
  Product.register(app)
  List.register(app)
  Blog.register(app)
  Project.register(app)
  Checkout.register(app)
  Collection.register(app)
  Faq.register(app)
  Promo.register(app)
}

export default {
  install,
}
