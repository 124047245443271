import { App } from 'vue'
import { tableWrapper } from './table'
import { accordion } from './accordion'
import { seoText } from './seoText'

const register = (app: App<Element>): void => {
  app.directive('table-wrapper', tableWrapper)
  app.directive('accordion', accordion)
  app.directive('seo-text', seoText)
}

export default {
  register,
}
