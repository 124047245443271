import { defineComponent as _defineComponent } from 'vue'
import { isEmpty } from 'lodash'

interface Props {
  id?: string,
  isRequired?: boolean,
  inputLabel: string,
  value: any,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ControlLabelBound',
  props: {
    id: {},
    isRequired: { type: Boolean },
    inputLabel: {},
    value: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props

const __returned__ = { props, get isEmpty() { return isEmpty } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})