import {
  baseResource,
} from '@/resource/resource'
import { prefixLanguage } from '@/utils/urls'
import { friendlyUrlGenerator } from '@/utils/friendly'

const FILTERS_LIST_URL_PREFIX = prefixLanguage('/')

const order = {
  path: [
    'prefix',
    'postfix',
    'label',
    'filters',
    'page',
  ],
}

export const blogCatalogResource = friendlyUrlGenerator([
  FILTERS_LIST_URL_PREFIX, 'ajax/blog'
], baseResource, order)
