/**
 * get cookie
 * @param {*} name
 */
export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(
        /([.$?*|{}()[\]\\/+^])/g,
        '\\$1'
      )}=([^;]*)`
    )
  )

  return matches ? decodeURIComponent(matches[1]) : undefined
}

/**
 * set cookie
 * @param {*} name
 * @param {*} val
 * @param {*} expires
 */
export function setCookie(name, val, expires) {
  const cookie = [name, '=', val, expires, '; path=/;'].join('')

  document.cookie = cookie
}
