import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced g-row--space_md g-row--space_2xl-xl" }
const _hoisted_2 = { class: "g-cell g-cols g-cols--12-xs" }
const _hoisted_3 = { class: "g-row g-row--appearance_spaced g-row--space_md g-row--space_2xl-xl g-row--align_center" }
const _hoisted_4 = { class: "g-cell g-cols g-cols--12-xs g-cols--4-lg g-cols--6-xl g-cols--4-hd" }
const _hoisted_5 = {
  key: 0,
  class: "g-cell g-cols g-cols--12-xs g-cols--8-lg g-cols--6-xl g-cols--8-hd"
}
const _hoisted_6 = { class: "ds-caption ds-caption--size_xs ds-caption--size_2xs-xl ds-caption--color_relief-4" }
const _hoisted_7 = {
  key: 1,
  class: "g-cell g-cols g-cols--12-xs g-cols--8-lg g-cols--6-xl g-cols--8-hd"
}
const _hoisted_8 = {
  key: 0,
  class: "ds-caption ds-caption--size_xs ds-caption--size_2xs-xl ds-caption--color_relief-4"
}
const _hoisted_9 = { class: "ds-caption ds-caption--size_xs ds-caption--size_2xs-xl ds-caption--color_relief-4" }
const _hoisted_10 = ["href"]
const _hoisted_11 = {
  key: 1,
  class: "ds-caption ds-caption--size_xs ds-caption--size_2xs-xl ds-caption--weight_medium ds-caption--color_dark"
}
const _hoisted_12 = {
  key: 0,
  class: "g-cell g-cols g-cols--12-xs"
}
const _hoisted_13 = { class: "g-row g-row--appearance_spaced g-row--space_md g-row--space_2xl-xl g-row--align_center" }
const _hoisted_14 = { class: "g-cell g-cols g-cols--8-lg g-cols--6-xl g-cols--8-hd" }
const _hoisted_15 = {
  key: 0,
  class: "g-cell g-cols g-cols--12-xs g-cols--4-lg g-cols--6-xl g-cols--4-hd"
}
const _hoisted_16 = ["href"]
const _hoisted_17 = { class: "g-cell g-cols g-cols--12-xs" }
const _hoisted_18 = { class: "g-row g-row--appearance_spaced g-row--space_md g-row--space_2xl-xl g-row--align_center" }
const _hoisted_19 = { class: "g-cell g-cols g-cols--12-xs g-cols--4-lg g-cols--6-xl g-cols--4-hd" }
const _hoisted_20 = { class: "g-cell g-cols g-cols--12-xs g-cols--8-lg g-cols--6-xl g-cols--8-hd" }
const _hoisted_21 = { class: "ds-caption ds-caption--size_xs ds-caption--size_2xs-xl ds-caption--color_relief-4" }
const _hoisted_22 = { class: "g-cell g-cols g-cols--12-xs" }
const _hoisted_23 = { class: "g-row g-row--appearance_spaced g-row--space_md g-row--space_2xl-xl g-row--align_center" }
const _hoisted_24 = { class: "g-cell g-cols g-cols--12-xs g-cols--4-lg g-cols--6-xl g-cols--4-hd" }
const _hoisted_25 = { class: "error-label" }
const _hoisted_26 = { class: "g-cell g-cols g-cols--12-xs" }
const _hoisted_27 = { class: "g-row g-row--appearance_spaced g-row--space_md g-row--space_2xl-xl g-row--align_start" }
const _hoisted_28 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm g-cols--4-lg g-cols--6-xl g-cols--4-hd" }
const _hoisted_29 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm g-cols--4-lg g-cols--6-xl g-cols--4-hd" }
const _hoisted_30 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm g-cols--2-lg g-cols--6-xl g-cols--2-hd" }
const _hoisted_31 = { class: "g-cell g-cols g-cols--12 g-cols--6-sm g-cols--2-lg g-cols--6-xl g-cols--2-hd" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_select = _resolveComponent("control-select")!
  const _component_control_input = _resolveComponent("control-input")!
  const _component_VeeError = _resolveComponent("VeeError")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_control_select, {
            modelValue: $props.formdata.deliveryWay,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => (($props.formdata.deliveryWay) = $event)),
              _cache[1] || (_cache[1] = ($event: any) => ($setup.emit('delivery:way:changed')))
            ],
            "option-label": "title",
            name: "deliveryWay",
            rules: "required",
            "allow-empty": false,
            options: $props.choices.deliveryWays,
            errors: $props.errors,
            "input-label": _ctx.$t("Способ доставки")
          }, null, 8, ["modelValue", "options", "errors", "input-label"])
        ]),
        ($setup.isCourier && $setup.hasDescription)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, _toDisplayString($props.formdata.deliveryWay.description), 1)
            ]))
          : _createCommentVNode("", true),
        ($setup.isPickup)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              ($setup.hasDescription || $props.choices.pickupAddresses.length === 1)
                ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString($props.formdata.deliveryWay.description), 1))
                : _createCommentVNode("", true),
              ($props.choices.pickupAddresses.length === 1)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("Адресс пункта самовывоза")), 1),
                    ($props.formdata.pickupAddress.address_url)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          class: "ds-link ds-link--size_xs ds-link--size_2xs-xl ds-link--weight_medium ds-link--color_dark ds-link--inline ds-link--hover_underline",
                          href: $props.formdata.pickupAddress.address_url,
                          target: "_blank",
                          rel: "noopener noreferrer"
                        }, _toDisplayString($props.formdata.pickupAddress.address), 9, _hoisted_10))
                      : (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString($props.formdata.pickupAddress.address), 1))
                  ], 64))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    ($props.choices.pickupAddresses.length > 1 && $setup.isPickup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_control_select, {
                modelValue: $props.formdata.pickupAddress,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($props.formdata.pickupAddress) = $event)),
                "option-label": "address",
                name: "pickupAddress",
                rules: "required",
                "allow-empty": false,
                options: $props.choices.pickupAddresses,
                errors: $props.errors,
                "input-label": _ctx.$t("Выберите точку самовывоза")
              }, null, 8, ["modelValue", "options", "errors", "input-label"])
            ]),
            ($props.formdata.pickupAddress && $props.formdata.pickupAddress.address_url)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("a", {
                    class: "ds-link ds-link--size_xs ds-link--size_2xs-xl ds-link--inline",
                    href: $props.formdata.pickupAddress.address_url,
                    target: "_blank",
                    rel: "noopener noreferrer"
                  }, _toDisplayString(_ctx.$t("Показать на карте")), 9, _hoisted_16)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    ($setup.isCourier)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_control_input, {
                  name: "country",
                  type: "text",
                  rules: "required",
                  errors: $props.errors,
                  "input-label": _ctx.$t("Страна"),
                  readonly: true,
                  value: $props.currentCountry
                }, null, 8, ["errors", "input-label", "value"])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.$t("Изменить страну доставки вы можете в шапке сайта. После изменения цена товара будет пересчитана согласно тарифам выбранной страны")), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_component_control_input, {
                  name: "postcode",
                  type: "text",
                  rules: "required|max:20",
                  errors: $props.errors,
                  "input-label": _ctx.$t("Индекс"),
                  modelValue: $props.formdata.postcode,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($props.formdata.postcode) = $event)),
                  onInput: _cache[4] || (_cache[4] = ($event: any) => ($setup.emit('postalcode:changed')))
                }, null, 8, ["errors", "input-label", "modelValue"]),
                _createVNode(_component_VeeError, { name: "postcodeCustom" }, {
                  default: _withCtx(({ message }) => [
                    _createElementVNode("span", _hoisted_25, _toDisplayString(message), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createVNode(_component_control_input, {
                  name: "city",
                  type: "text",
                  rules: "required|max:100",
                  errors: $props.errors,
                  "input-label": _ctx.$t("Город"),
                  modelValue: $props.formdata.city,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($props.formdata.city) = $event))
                }, null, 8, ["errors", "input-label", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_29, [
                _createVNode(_component_control_input, {
                  name: "street",
                  type: "text",
                  rules: "required|max:150",
                  errors: $props.errors,
                  "input-label": _ctx.$t("Улица"),
                  modelValue: $props.formdata.street,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($props.formdata.street) = $event))
                }, null, 8, ["errors", "input-label", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_30, [
                _createVNode(_component_control_input, {
                  name: "streetNo",
                  type: "text",
                  rules: "required|max:20",
                  errors: $props.errors,
                  "input-label": _ctx.$t("Дом"),
                  modelValue: $props.formdata.streetNo,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($props.formdata.streetNo) = $event))
                }, null, 8, ["errors", "input-label", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createVNode(_component_control_input, {
                  name: "apartment",
                  type: "text",
                  rules: "max:20",
                  errors: $props.errors,
                  "input-label": _ctx.$t("Квартира"),
                  modelValue: $props.formdata.apartment,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($props.formdata.apartment) = $event))
                }, null, 8, ["errors", "input-label", "modelValue"])
              ])
            ])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}