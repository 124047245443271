import useGlobal from '@/composables/useGlobal'

interface Language {
  value: string;
  label: string;
}

interface Network {
  network: string;
  title: string;
  icon: string;
  color: string;
}

interface Choices {
  languages: Language[];
  networksForShare: Network[];
}

export default function (): Choices {
  const { $t } = useGlobal()

  const languages = [
    {
      value: 'lv',
      label: $t('lv'),
    },
    {
      value: 'lt',
      label: $t('lt'),
    },
    {
      value: 'et',
      label: $t('et'),
    },
    {
      value: 'ru',
      label: $t('ru'),
    },
  ]

  const networksForShare = [
    {
      network: 'facebook',
      title: $t('Facebook'),
      icon: 'icon-facebook',
      color: '#3b5998',
    },
    {
      network: 'twitter',
      title: $t('Twitter'),
      icon: 'icon-twitter',
      color: '#1da1f2',
    },
    {
      network: 'viber',
      title: $t('Viber'),
      icon: 'icon-viber',
      color: '#7360f2',
    },
    {
      network: 'whatsapp',
      title: $t('Whatsapp'),
      icon: 'icon-whatsapp',
      color: '#25d366',
    },
    {
      network: 'telegram',
      title: $t('Telegram'),
      icon: 'icon-telegram',
      color: '#0088cc',
    },
    {
      network: 'linkedin',
      title: $t('Linkedin'),
      icon: 'icon-linkedin',
      color: '#0a66c2',
    },
  ]

  return {
    languages,
    networksForShare,
  }
}